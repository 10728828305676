
import React, { Component } from 'react';
import {Input , Button,InputNumber ,message } from 'antd'
import { Spin } from 'antd';
// import BraftEditor from 'braft-editor';
import './RecruitmentEdit.css'
import cookie from '../../utils/utils'
const getCookie = cookie.getCookie

class RecruitmentEdit extends Component{
  state={
    classify:"",
    title: "",
    myid:0,
    spinning:false,
    editorState: '', // 设置编辑器初始内容
    outputHTML: '<p></p>',
    num:"",
    textareaText:"",
    inputValue: ''
  }
  handleChange = (editorState) => {
    this.setState({
      editorState: editorState,
      outputHTML: editorState.toHTML()
    })
  }
  componentDidMount(){
    this.setState({
      spinning:true
    })
    if(this.props.location.state){
      //修改的代码  后面要判断是否为空  新建  todo
      let myId = Number(this.props.location.state.data)
      this.setState({
        myid:myId
      })
      // http://120.26.61.246:9002/zhongcai/offer/?pageSize=10
      fetch('/zhongcai/offer/?pageSize=10',{
        method:"GET",
        headers: new Headers({
          'token': getCookie('token')
        })
      }).then((res)=>{
        return res.json();
      }).then(res => {
        this.setState({
          spinning:false
        })
        let arr = res.data.pageInfo.list || "";
        arr.forEach((item,index) => {
          if(myId === item.id){
            this.refs['title' ].input.value = item.post
            this.refs['textarea'].value = item.description
            // this.refs['num'].inputNumberRef.input.value = item.number
            this.setState({
              num:item.number,
              // editorState: BraftEditor.createEditorState(item.description),
              inputValue: item.post
            })  
          }
        });
      }).catch((res)=>{
          console.log(res);
      });
    }else{
      this.setState({
        spinning:false
      })
    }
  }
  inputChange(e) {
    this.setState({
      inputValue: e.currentTarget.value
    })
  }
  rua(){


    let inputTitle = this.refs['title']
    if(!inputTitle.input.value){
      message.info('岗位名称不能为空');
      return;
    }
    if(!this.refs['num'].inputNumberRef.input.value){
      message.info('人数不能为空');
      return;
    }
    //百度todo
    if(this.refs['textarea'].value === ""){
      message.info("岗位要求不能为空");
      return
    }
    // if(!this.state.editorState.toText()){
    //   message.info('岗位要求不能为空');
    //   return;
    // }
    this.setState({
      spinning:true
    })
    let json = {
      "createuser": 0,
      //百度todo
      // "description": this.state.editorState.toHTML(),
      "description":this.refs['textarea'].value,
      "id": this.state.myid,
      "number": this.refs['num'].inputNumberRef.input.value,
      "post": inputTitle.input.value,
      "status": true,
      "updateuser": 3
    }
    if(this.props.location.state){
      fetch('/zhongcai/offer/',{
        method:"PUT",
        body: JSON.stringify(json),
        headers: new Headers({
          'Content-Type': 'application/json',
          'token': getCookie('token')
        })
      }).then((res)=>{
        return res.json();
      }).then(res => {
        this.setState({
          spinning:false
        })
        message.info("修改成功")
        window.history.back();
      }).catch((res)=>{
        message.info("修改失败")
      });
    }else{
      // http://120.26.61.246:9002/zhongcai/offer/
      fetch('/zhongcai/offer/',{
        method:"POST",
        body: JSON.stringify(json),
        headers: new Headers({
          'Content-Type': 'application/json',
          'token': getCookie('token')
        })
      }).then((res)=>{
        return res.json();
      }).then(res => {
        this.setState({
          spinning:false
        })
        message.info("新建成功")
        window.history.back();
      }).catch((res)=>{
        message.info("新建失败")
      });
    }
  }
  back(){
    window.history.back();
  }
  render() {
    function onChange(value) {
        this.setState({
            classify:value,
            num:value
        })
    }
    
    return (
      <Spin spinning={this.state.spinning}>
      <div className="App" id='recruitmentedit'>
        <p style={{textAlign:"left"}}>岗位</p>
        <Input placeholder="岗位" size="large" id="ruarua" ref="title" value={this.state.inputValue} onChange={this.inputChange.bind(this)}></Input>
        <p style={{textAlign:"left"}}>人数</p>
        <InputNumber onChange={onChange.bind(this)} style={{float:"left"}} ref="num"  defaultValue={this.state.num} value={this.state.num}></InputNumber>
        <div style={{clear:"both"}}></div>
        <p style={{textAlign:"left"}}>要求</p>

        <textarea id="container" ref="textarea" style={{height:"500px",width:"100%"}}></textarea>
        {/* <BraftEditor
            style={{display:"none"}}
            value={this.state.editorState}
            onChange={this.handleChange}
          /> */}

        <Button onClick={this.back} style={{marginTop:'36px',float:"right",background:"#004894",color:"#fff",height:"45px",width:"120px",fontSize:"17px"}} icon='backward'>返回</Button>
        <Button onClick={this.rua.bind(this)} style={{marginTop:'36px',float:"right",marginRight:"36px",background:"#E50012",color:"#fff",height:"45px",width:"120px",fontSize:"17px"}} icon="upload">发布</Button>
      </div>
      </Spin>
    );
  }
}

export default RecruitmentEdit;