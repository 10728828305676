import React from "react"
import './Consult.css'
import { Button , Table , Spin ,message , Switch, Popconfirm, Input } from 'antd';
import { withRouter, Link} from "react-router-dom";
import cookie from '../../utils/utils'
const getCookie = cookie.getCookie
const InputGroup = Input.Group;
const Search = Input.Search;
class Consult extends React.Component {
  colAdd(){
      //只能新建一项的sid
    let arrId = ["9","11","14","18","19","20", "47", "46"]
    if(this.state.pid !== ""){
      window.localStorage.pid = this.state.pid
      window.localStorage.sid = this.state.sid
      
      for(let i=0;i<arrId.length;i++){
        if(window.localStorage.sid === arrId[i] && this.state.tableData.length){
          message.info("该项只能建一个")
          return 
        }
      }
      this.props.history.push({
        pathname:"/Home/ConsultEdit",
        state:{
          pid:window.localStorage.pid,
          sid:window.localStorage.sid
        }
      })
    }else{
      for(let i=0;i<arrId.length;i++){
        if(window.localStorage.sid === arrId[i]){
          message.info("该项只能建一个")
          return 
        }
      }
      this.props.history.push({
        pathname:"/Home/ConsultEdit",
        state:{
          pid:window.localStorage.pid,
          sid:window.localStorage.sid
        }
      })
    }    
  }
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    loading: false,
    tableData: [],
    //已选择的个数
    allNum:0,
    total:0,
    spinning:false,
    isCreate:true,
    isCreateId:{},
    pid:"",
    sid:"",
    onOffstate:false,
    pageSize: 10,
    pageNum: 1,
    findValue: '',
    switchLoading: {},
    switchChecked: {},
    isSearchStaut: false //处于搜索状态  分页使用搜索查询接口
  };
  //改变props传过来的值的时候改变
  componentWillReceiveProps(nextProps){
    //单独写的公告管理    
    if(nextProps.location.state){

      //是否存在新建
      this.setState({
        isCreate:true,
        pid : nextProps.location.state.pid,
        sid : nextProps.location.state.sid
      })
      if(nextProps.location.state.sid){
        let pid = nextProps.location.state.pid;
        let sid = nextProps.location.state.sid;
        var url = "&sid=" + sid
        this.query(url,pid)
        this.setState({
          isCreate:true
        })
      }else {
        let pid = nextProps.location.state.pid;
        url=''
        this.query(url,pid)
        this.setState({
          isCreate:false
        })
      }
    }else{
      this.setState({
        isCreate:false
      })
      fetch('/zhongcai/information/?pageSize=10', {
        headers: new Headers({
          'token': getCookie('token')
        })
      }).then((res)=>{
        return res.json();
      }).then(res => {
        this.setState({
          spinning:false,
          total:res.data.pageInfo.total
        })
        let data = res.data.pageInfo.list
        let arr = []
        data.forEach((item, index) => {
          let obj
          if(parseInt(item.secondaryId) === 37){
            obj = {
              key: item.id,
              name: item.sname,
              age: item.title,
              address: item.createat,
              shows: item.shows,
              source: item.source
            }
          }else{
            obj = {
              key: item.id,
              name: item.pname+'>'+item.sname,
              age: item.title,
              address: item.createat,
              shows: item.shows,
              source: item.source
            }
          }
          arr[index] = obj
        });
        this.setState({
          tableData:arr
        })
      }).catch((res)=>{
          console.log(res);
      });
    }

  }
 
  query(url, pid){
    this.setState({
      spinning: true,
      isSearchStaut: false
    })
    document.querySelector('.ant-input.ant-input-lg').value = ""
    //分页查询
    fetch(`/zhongcai/information/?pageSize=${this.state.pageSize}&pid=${pid + url}`, {
      method:"GET",
      headers: new Headers({
        'token': getCookie('token')
      })
    }).then((res)=>{
      return res.json();
    }).then(res => {
      this.setState({
        spinning:false
      })
      let data = res.data.pageInfo.list
      let arr = []
      data.forEach((item, index) => {
        let obj
        if(parseInt(item.secondaryId) === 37){
          obj = {
            key: item.id,
            name: item.sname,
            age: item.title,
            address: item.createat,
            shows: item.shows,
            source: item.source
          }
        }else{
          obj = {
            key: item.id,
            name: item.pname+'>'+item.sname,
            age: item.title,
            address: item.createat,
            shows: item.shows,
            source: item.source
          }
        }
        arr[index] = obj
      });
      this.setState({
        tableData:arr,
        total: res.data.pageInfo.total,
        pageNum: res.data.pageInfo.pageNum
      })
      this.setState({
        isCreateId:{
          pid:data[0].pid,
          sid:data[0].sid
        }
      })
    }).catch((res)=>{
        console.log(res);
    });
  }

  componentDidMount() { 
    this.setState({
      spinning:true,
      isCreate:false,
    })
  //传过来的值
  if(this.props.location.state){
      if(this.props.location.state.sid){
        let pid = this.props.location.state.pid;
        let sid = this.props.location.state.sid;
        var url = "&sid="+sid
        this.query(url,pid)
        this.setState({
          isCreate:true
        })
      }else {
        let pid = this.props.location.state.pid;
        url=''
        this.query(url,pid)
        this.setState({
          isCreate:false
        })
      }
    }else{
      //查看全部
      fetch('/zhongcai/information/?pageSize=10', {
        headers: new Headers({
          'token': getCookie('token')
        })
      }).then((res)=>{
        return res.json();
      }).then(res => {
        this.setState({
          spinning:false,
          total: res.data.pageInfo.total
        })
        let data = res.data.pageInfo.list
        let arr = []
        
        data.forEach((item, index) => {
          let obj
          if(parseInt(item.secondaryId) === 37){
            obj = {
              key: item.id,
              name: item.sname,
              age: item.title,
              address: item.createat,
              shows: item.shows,
              source: item.source
            }
          }else{
            obj = {
              key: item.id,
              name: item.pname+'>'+item.sname,
              age: item.title,
              address: item.createat,
              shows: item.shows,
              source: item.source
            }
          }
          arr[index] = obj
        });
        this.setState({
          tableData:arr
        })
      }).catch((res)=>{
          console.log(res);
      });
    }
  }
  //初次请求
  firstTorua(){
    this.setState({
      spinning:true
    })
    fetch('/zhongcai/information/?&pageSize=10',{
      method:"GET",
      headers: new Headers({
        'token': getCookie('token')
      })
    }).then((res)=>{
      return res.json();
    }).then(res => {
      this.setState({
        spinning:false,
        total:res.data.pageInfo.total
      })
    }).catch((res)=>{
        console.log(res);
        this.setState({
          spinning:false
        })
    });
  }
  
  myOnchange(pagination){
    if(this.state.isSearchStaut) {
      this.showAll(this.state.findValue, this.state.pid, this.state.sid, pagination.pageSize, pagination.current)
      return
    }
    this.setState({
      spinning: true
    })
    // http://120.26.61.246:9002/zhongcai/information/?pageNum=2&pageSize=10
    let url = `/zhongcai/information/?pageNum=${pagination.current}&pageSize=${pagination.pageSize}`
    if(this.state.sid) {
      url += `&sid=${this.state.sid}`
    }
    if(this.state.pid) {
      url += `&pid=${this.state.pid}`
    }
     fetch(url, {
      headers: new Headers({
        'token': getCookie('token')
      })
     }).then((res)=>{
      return res.json();
    }).then(res => {
      this.setState({
        spinning:false
      })
      let data = res.data.pageInfo.list
      let arr = []
      data.forEach((item, index) => {
        let obj
        if(parseInt(item.secondaryId) === 37){
          obj = {
            key: item.id,
            name: item.sname,
            age: item.title,
            address: item.createat,
            shows: item.shows,
            source: item.source
          }
        }else{
          obj = {
            key: item.id,
            name: item.pname+'>'+item.sname,
            age: item.title,
            address: item.createat,
            shows: item.shows,
            source: item.source
          }
        }
        arr[index] = obj
      });
      this.setState({
        tableData: arr,
        pageNum: res.data.pageInfo.pageNum
      })
    }).catch((res)=>{
        console.log(res);
    });   
  }

  start = () => {
    this.setState({ loading: true });
  // ajax request after empty completing
    setTimeout(() => {
        this.setState({
        selectedRowKeys: [],
        loading: false,
        //总数
        allNumber:0,
        //传递过去的Id
        transmitId:""
        });
    }, 1000);
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ 
      selectedRowKeys: selectedRowKeys,
      allNumber:selectedRowKeys.length
    });
  }
  //修改
  amend(key) {
    console.log(key)
    window.localStorage.myid = key 
    // http://120.26.61.246:9002/zhongcai/information/50
    fetch('/zhongcai/information/'+key,{
      method:"GET",
      headers: new Headers({
        'token': getCookie('token')
      })
    }).then((res)=>{
      return res.json();
    }).then(res => {
      window.localStorage.pid = res.data.information.primaryId 
      window.localStorage.sid = res.data.information.secondaryId 
    }).catch((res)=>{
      console.log("修改失败")
    });
    this.setState({
      transmitId:key
    })
  }
  //多选清空&&单清空
  clear(arr){
    this.setState({
      spinning:true
    })
    let arrSplice = this.state.tableData
    if(typeof arr == "number"){
      fetch('/zhongcai/information/'+arr,{
        method:"DELETE",
        headers: new Headers({
          'token': getCookie('token')
        })
      }).then((res)=>{
        return res.json();
      }).then(res => {
        this.setState({
          spinning:false
        })
        arrSplice.forEach((item,index,array)=>{
          if(item.key===arr){
            array.splice(index,1)
          }
        })
        this.setState({
          tableData:arrSplice
        })
        message.info("删除成功")
      }).catch((res)=>{
        message.info("删除失败")
      });
    } else {
      this.setState({
        spinning:true
      })
      for(var i=0;i<arrSplice.length;i++){
        for(var j=0;j<arr.length;j++){
          if(arrSplice[i].key === arr[j] ){
            arrSplice.splice(i,1)
            fetch('/zhongcai/information/'+arr[j],{
              method:"DELETE",
              headers: new Headers({
                'token': getCookie('token')
              })
            }).then((res)=>{
              return res.json();
            }).then(res => {
              this.setState({
                spinning:false
              })
              message.info('删除成功')
            }).catch((res)=>{
                message.info('删除失败')
            });
          }
        }
      }
    }
    this.setState({
      tableData:arrSplice
    })
    // this.firstTorua();
  }

  onOff(checked, key){
    let switchLoading = this.state.switchLoading
    let data = this.state.tableData   
    switchLoading[checked.key] = true
    this.setState({
      switchLoading
    })
    let shows = 1
    if(key) {
      shows = 0
    }
    fetch(`/zhongcai/information/show?Id=${checked.key}&show=${shows}`,{
      method:"GET",
      headers: new Headers({
        'token': getCookie('token')
      })
    }).then((res)=>{
      if(res.status >= 500) {
        message.error('服务器出错，请稍后重试', 3)
        return false
      }
      return res.json();
    }).then(res => {
      if(!res) {
        return false
      }
      switchLoading[checked.key] = false
      data.forEach(item => {
        if(item.key === checked.key) {
          item.shows = key ? 0 : 1
          return
        }
      })
      this.setState({
        switchLoading,
        tableData: data
      })
      message.success('操作成功', 3)
    }).catch((res)=>{
      message.error('操作失败，请重试', 3)
      switchLoading[checked.key] = false
      this.setState({
        switchLoading
      })
    });
    return false
  }

  onSearch(value){
    if(!value.trim()) {
      message.error('搜索内容不能为空！', 3)
      return false;
    }
    this.setState({
      findValue: value,
      isSearchStaut: true
    })
    this.showAll(value, this.state.pid, this.state.sid, this.state.pageSize, 1)
    //todo  根据 姓名专业居住地查找
  }

  showAll(value, pId, sId, pageSize, pageNum) {
    this.setState({
      spinning: true
    })
    fetch(`/zhongcai/information/titleAndId?title=${value}&sid=${sId}&pid=${pId}&pageNum=${pageNum}&pageSize=${pageSize}`, {
      headers: new Headers({
        'token': getCookie('token')
      })
    })
    .then((res)=>{
      return res.json();
    }).then(res => {
      this.setState({
        spinning: false,
        total: res.total
      })
      let data = res.list
      let arr = []
      data.forEach((item, index) => {
        let obj
        if(parseInt(item.secondaryId) === 37){
          obj = {
            key: item.id,
            name: item.sname,
            age: item.title,
            address: item.createat,
            shows: item.shows,
            source: item.source
          }
        }else{
          obj = {
            key: item.id,
            name: item.pname+'>'+item.sname,
            age: item.title,
            address: item.createat,
            shows: item.shows,
            source: item.source
          }
        }
        arr[index] = obj
      });
      this.setState({
        tableData: arr,
        pageNum: res.pageNum
      })
    }).catch((res)=>{
        console.log(res);
    });
  }

  render() {
    const columns = [{
      title: '分类',
      dataIndex: 'name',
    }, {
      title: '标题',
      dataIndex: 'age',
      render: (text) => <span className="col-sql" title={text}>{text}</span>,
    },
    {title: "发布者姓名",
  dataIndex: 'source'},
    {
      title: '时间',
      dataIndex: 'address',
    },{
      title: '操作',
      dataIndex: 'key', key: 'x', render: (data, record) => 
        <div>
          <Link to={
            {
              pathname:"/Home/ConsultEdit", 
              state: {data:`${record.key}`}  
            }
          }
          ><Button icon="form" style={{marginRight:"12px",outline: "none",border: "none",boxShadow: "none",marginLeft:"-16px"}} onClick={this.amend.bind(this,record.key)}>修改</Button></Link>
          <Popconfirm title="确定删除" okText="确定" cancelText="取消" onConfirm={this.clear.bind(this, record.key)}>
            <Button icon="delete" style={{marginRight:"12px",outline: "none",border: "none",boxShadow: "none"}} >删除</Button>
          </Popconfirm>       
          <Switch checkedChildren="显示" unCheckedChildren="不显示" checked={record.shows === 0} onChange={this.onOff.bind(this, record)} loading={this.state.switchLoading[record.key]}/>
        </div>
    }
  ];

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div className="example" id='example'>
          <div style={{display: this.state.isCreate ? "block" : "none"}}>
            <Button type="primary" icon="plus" onClick={this.colAdd.bind(this)}>新建</Button>
            <InputGroup 
              compact
              style={{marginBottom:"31px"}}
            >
              <Search
                placeholder="search"
                onSearch={this.onSearch.bind(this)}
                style={{ width: 330 }}
                size='large'
              />
              <Button className="buttonBox" onClick={this.query.bind(this, `&sid=${this.state.sid}`, this.state.pid)}>显示全部</Button>
            </InputGroup>
          </div>
          <div className="info" style={{display:this.state.selectedRowKeys.length ? "block" : "none"}}>
            <Button icon="exclamation-circle" style={{border:"none",outline:"none",boxShadow:"none",color:"#2a8ff6",backgroundColor:"#e5f7ff"}}></Button>
            <span>已选择<span className="addNumber">{this.state.allNumber || 0}</span>项</span>
            <Popconfirm title="确定删除" okText="确定" cancelText="取消" onConfirm={this.clear.bind(this,this.state.selectedRowKeys)}>
              <span className="clearAll">删除</span>
            </Popconfirm>
            
          </div>
          <div>
          <div style={{ marginBottom: 16 }}>
            <span style={{ marginLeft: 8 }}>
            </span>
            </div>
            <Spin
              spinning={this.state.spinning}
            > 
              <Table locale={{emptyText: '暂无数据' }} rowSelection={rowSelection} pagination={{total: this.state.total, current: this.state.pageNum, pageSize: this.state.pageSize}} onChange={this.myOnchange.bind(this)} columns={columns} dataSource={this.state.tableData} />
            </Spin>
            </div>
      </div>
    );
  }
}
export default withRouter(Consult);

