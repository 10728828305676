import React from 'react';
import './index.css'
import banner from '../../img/bg-banner.png'
import logo from '../../img/login.png'
import { message, Spin, Modal } from 'antd'
import store from '../../redux/store'
import cookie from '../../utils/utils'
import JSEncrypt from 'jsencrypt';
const setCookie = cookie.setcookie


class Login extends React.Component {
    state = {
        height: 720,
        spinning: false,
        show1: false,
        publicKey: ''
    };
    componentDidMount() {
        document.addEventListener("keydown", (e) => {
            if (e.keyCode === 13) {
                this.login()
            }
        })
        this.setState({
            height: document.documentElement.clientHeight
        })
        store.subscribe(() => console.log(store.getState()))
        store.dispatch({
            type: "ADD",
            payload: '范诗豪'
        });
        this.getPublicKey();

    }

    getPublicKey() {
        fetch('/zhongcai/author/getKey', {
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        })
            .then(res => {
                return res.json();
            })
            .then(res => {
                this.setState({
                    publicKey: res.key
                })
            })
    }

    login() {
        this.setState({
            spinning: true
        })
        let account = this.refs.name.value
        let password = this.refs.password.value
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(this.state.publicKey);
        let passwordEncrypt = encrypt.encrypt(password);
        let obj = {
            username: account,
            password: passwordEncrypt
        }
        //http://120.26.61.246:9002/zhongcai/author/loginPost?account=rua&password=123456
        fetch('/zhongcai/author/loginPost', {
            method: "POST",
            body: JSON.stringify(obj),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        }).then((res) => {
            return res.json();
        }).then(res => {
            this.setState({
                spinning: false
            })
            if (res.errno === 0) {
                setCookie("username", account)
                setCookie("token", res.data.token, 7)
                setCookie("islogin", res.success)
                message.info("登录成功")
                this.props.history.push({
                    pathname: "/Home",
                    query: {
                        username: account
                    }
                })


            } else {
                message.info(res.errmsg)
            }

        }).catch((res) => {
            this.setState({
                spinning: false
            })
            message.info("网络错误")
        });
    }

    caretePid0() {
        let obj = {
            name: "仲裁鉴定机构年审",
            pid: 0,
            remarks: ""
        }
        fetch(`zhongcai/menu?name=${obj.name}&pid=${obj.pid}&remarks=${obj.remarks}`, {
            method: "POST",
            body: JSON.stringify(obj),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        }).then((res) => {
            return res.json();
        }).then(res => {
            this.setState({
                spinning: false
            })

        }).catch((res) => {

        });
    }

    render() {
        return (
            <Spin spinning={this.state.spinning}>
                <div id='login' style={{ height: this.state.height }}>
                    <img src={banner} alt='' style={{ height: this.state.height, width: "100%" }}></img>
                    <div className="content">
                        <div className='header'>
                            <img src={logo} alt='' className="logoimg"></img>
                            <div className="logoname">
                                <span className="font-cn">长沙仲裁委员会后台管理平台</span>
                                <span className="font-en">Background management platform</span>
                            </div>
                        </div>

                        <div className='tolog'>
                            <p className='input1'>登录名</p>
                            <input type='text' className='logoinput' ref="name"></input>
                            <p className='input1' style={{ marginTop: "14px" }}>登录密码</p>
                            <input type="password" className='logoinput' style={{ marginBottom: "59px" }} ref="password"></input>
                            <button className="btnlogin" onClick={this.login.bind(this)} >登 录</button>
                        </div>
                    </div>
                </div>
            </Spin>
        );
    }
}
export default Login