let defaultValue = {
    sid: 0,
    pid: 1
}
export default function num(state = defaultValue, action) {
    switch (action.type){
        case "ADD":
            return Object.assign({}, state, {
                sid: 'fanshihao'
            })
        default:
            return state
    }     
}