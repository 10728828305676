import React from "react"
import './Register.css'
import { Button, Table, Spin, message, Popconfirm, Input, Icon, Select } from 'antd';
import { Link, withRouter } from "react-router-dom";
import * as XLSX from 'xlsx'
import { Ajax } from "../../utils/ajax";
import example from '../../名册导入模板.xlsx'
import cookie from '../../utils/utils'
const getCookie = cookie.getCookie
const InputGroup = Input.Group;
const Option = Select.Option;
const Search = Input.Search;
class Recruitment extends React.Component {
  rua() {
    this.props.history.push({
      pathname: '/Home/RegisterEdit',
      state: {
        category: this.props.location.state
      }
    })

  }
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    loading: false,
    tableData: [],
    //已选择的个数
    allNum: 0,
    page: 0,
    pageSize: 10,
    pageNum: 1,
    total: 0,
    pagination: {},
    spinning: false,
    findType: 1,
    findValue: '',
    groupClass: "金融仲裁员",
    sortOrderArr: [{
      key: 'name',
      value: 'asc'
    }, {
      key: 'gender',
      value: 'asc'
    }, {
      key: 'technicalTitle',
      value: 'asc'
    }, {
      key: 'profession',
      value: 'asc'
    }, {
      key: 'address',
      value: 'asc'
    }, {
      key: 'careerClass',
      value: 'asc'
    }, {
      key: 'foreignLanguage',
      value: 'asc'
    }],
    filtSortName: '',
    filtSortOrder: ''
  };

  start = () => {
    this.setState({ loading: true });
    // ajax request after empty completing
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
        //总数
        allNumber: 0
      });
    }, 1000);
  }
  onSelectChange = (selectedRowKeys) => {
    this.setState({
      selectedRowKeys: selectedRowKeys,
      allNumber: selectedRowKeys.length
    });
  }
  test(key) {
    this.setState({
      transmitId: key
    })
  }
  //多选清空&&单清空
  clear(arr) {
    this.setState({
      spinning: true
    })
    // http://120.26.61.246:9002/zhongcai/roster/1
    let arrSplice = this.state.tableData
    if (typeof arr == "number") {

      fetch('/zhongcai/roster/' + arr, {
        method: "DELETE",
        headers: new Headers({
          'token': getCookie('token')
        })
      }).then((res) => {
        return res.json();
      }).then(res => {
        this.setState({
          spinning: false
        })
        arrSplice.forEach((item, index, array) => {
          if (item.key === arr) {
            array.splice(index, 1)
          }
        })
        this.setState({
          tableData: arrSplice
        })
        message.info("删除成功")
        this.componentDidMount()
      }).catch((res) => {
        console.log(res);
        message.info("删除失败")
      });
    }
    else {
      fetch('/zhongcai/roster/batchDel?idList=' + this.state.selectedRowKeys.join(','), {
        method: "GET",
        headers: new Headers({
          'token': getCookie('token')
        })
      }).then((res) => {
        return res.json();
      }).then(res => {
        this.setState({
          spinning: false
        })
        message.info("删除成功")
        this.componentDidMount()
      }).catch((res) => {
        console.log(res);
        message.info("删除失败")
      });
      // for(var i=0;i<arrSplice.length;i++){
      //   for(var j=0;j<arr.length;j++){
      //     if(arrSplice[i].key === arr[j] ){
      //       arrSplice.splice(i,1)
      //     }
      //   }
      // }
    }


    this.setState({
      tableData: arrSplice
    })
  }
  myOnchange(pagination, filters, sorter) {
    console.log(pagination, sorter)
    // let sortOrder = [];
    let index;
    let thisItem;
    let sortOrderArr = this.state.sortOrderArr.map((item, idx, arr) => {
      if (item.key === sorter.columnKey) {
        item.value = sorter.order == 'ascend' ? 'asc' : 'desc';
        index = idx
        thisItem = item
      }
      return item;
    });
    if(index) {
      sortOrderArr.splice(index, 1);
      sortOrderArr.unshift(thisItem);
    }
  
    
    let sortNameArr = []
    let sortOrder = []
    sortOrderArr.forEach((item, idx, arr) => {
      sortNameArr.push(item.key)
      sortOrder.push(item.value)
    })

    const filtSortName = sortNameArr.join(',')
    const filtSortOrder = sortOrder.join(',')
    this.setState({
      sortOrderArr:sortOrderArr,
      filtSortName: filtSortName,
      filtSortOrder: filtSortOrder,
      pageNum: pagination.current
    }, () => {
      this.showAll(pagination.pageSize, pagination.current, this.state.findType, this.state.findValue, filtSortName, filtSortOrder)
    })



    // this.state.sortNameArr.unshift(sorter.columnKey);
    // let newArr = [];
    // this.state.sortNameArr.forEach(item => {
    //   if (newArr.indexOf(item) === -1) {
    //     newArr.push(item);
    //   }
    // })
    // let filterSortNewArr = newArr.filter(item => {
    //   if (item !== undefined) {
    //     return item;
    //   }
    // })
    // let sortOrder = [];
    // this.state.sortOrderArr.forEach((item, idx, arr) => {
    //   if (item.key === sorter.columnKey) {
    //     item.value = sorter.order === 'ascend' ? 'asc' : 'desc';

    //   }
    //   sortOrder.push(item.value)
    // })

    // const filtSortName = [...sorter.columnKey,...this.state.sortNameArr]
    // console.log(filtSortName)
    // const filtSortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state) {
      this.changeShow(nextProps)
    } else {
      this.showAll(this.state.pageSize, this.state.pageNum)
    }
  }
  changeShow(props) {
    let myTitle = props.location.state.mytitle || '金融仲裁员'
    this.setState({
      spinning: true,
      groupClass: myTitle
    })
    // http://120.26.61.246:9002/zhongcai/roster/?pageSize=10&groupClass=%E9%A6%96%E5%B8%AD%E4%B8%93%E5%AE%B6
    fetch('/zhongcai/roster/queryBySortName?pageSize=10&groupClass=' + myTitle + '&sortName=' + 'name,technicalTitle,profession,address,careerClass,foreignLanguage,gender' + '&sortOrder=' + 'asc,asc,asc,asc,asc,asc,asc', {
      headers: new Headers({
        'token': getCookie('token')
      })
    }).then((res) => {
      return res.json();
    }).then(res => {
      this.setState({
        spinning: false
      })
      let data = res && res.data.pageInfo.list
      let arr = []
      data.forEach((item, index) => {
        let obj = {
          key: item.id,
          name: item.name,
          gender: item.gender === 0 ? "女" : "男",
          technicalTitle: item.technicalTitle,
          profession: item.profession,
          address: item.address,
          careerClass: item.careerClass,
          foreignLanguage: item.foreignLanguage
        }
        arr[index] = obj
      });
      this.setState({
        tableData: arr,
        total: res.data.pageInfo.total
      })
    }).catch((res) => {
      this.setState({
        spinning: false
      })
    });
  }
  componentDidMount() {
    this.setState({
      spinning: true
    })
    if (this.props.location.state) {
      this.changeShow(this.props)
    } else {
      this.showAll(this.state.pageSize, this.state.pageNum)
    }
  }
  showAll(pageSize, pageNum, type = '', str = '', sortName, sortOrder, isAll = false) {
   
    this.setState({
      spinning: true
    })
    if (isAll) {
      this.setState({
        findValue: ''
      })
      document.querySelector('.ant-input.ant-input-lg').value = ""
    }
    let url = ''
    url = '/zhongcai/roster/queryBySortName?pageSize=' + pageSize + '&pageNum=' + pageNum
    if (type !== '' && str !== '') {
      url = '/zhongcai/roster/queryBySortName?pageSize=' + pageSize + '&pageNum=' + pageNum + '&findType=' + encodeURIComponent(type) + '&str=' + encodeURIComponent(str);
    }
    if (sortName) {
      url = '/zhongcai/roster/queryBySortName?pageSize=' + pageSize + '&pageNum=' + pageNum + '&findType=' + encodeURIComponent(type) + '&str=' + encodeURIComponent(str) + '&sortName=' + sortName + '&sortOrder=' + sortOrder;
    } else {
      url = '/zhongcai/roster/queryBySortName?pageSize=' + pageSize + '&pageNum=' + pageNum + '&findType=' + encodeURIComponent(type) + '&str=' + encodeURIComponent(str) + '&sortName=' + 'name,technicalTitle,profession,address,careerClass,foreignLanguage,gender' + '&sortOrder=' + 'asc,asc,asc,asc,asc,asc,asc';
    }
    let category = '';
    if (this.props.location.state) {
      category = this.props.location.state.mytitle;
      url += '&groupClass=' + encodeURIComponent(category);
    } else {
      category = '金融仲裁员';
      url += '&groupClass=' + encodeURIComponent(category);
    }
    fetch(url, {
      method: "GET",
      headers: new Headers({
        'token': getCookie('token')
      })
    }).then((res) => {
      if (res.status >= 500) {
        message.error('服务器出错，请稍后重试', 3)
        return false
      }
      return res.json();
    }).then(res => {
      if (!res) {
        return
      }
      this.setState({
        spinning: false
      })
      let arr = []
      let data = res && res.data.pageInfo.list
      data.forEach((item, index) => {
        let obj = {
          key: item.id,
          name: item.name,
          gender: item.gender === 0 ? "女" : "男",
          technicalTitle: item.technicalTitle,
          profession: item.profession,
          address: item.address,
          careerClass: item.careerClass,
          foreignLanguage: item.foreignLanguage
        }
        arr[index] = obj
      });
      this.setState({
        tableData: arr,
        pageNum: res.data.pageInfo.pageNum,
        total: res.data.pageInfo.total
      })
    })
  }
  onOff(checked, key) {
  }
  handleChange(e) {
    this.setState({
      findType: e
    })
  }
  onSearch(value) {
    if (!value.trim()) {
      message.error('搜索内容不能为空！', 3)
      return false;
    }
    this.setState({
      findValue: value
    }, () => {
      this.showAll(10, 1, this.state.findType, value, this.state.filtSortName, this.state.filtSortOrder)
    })
    //todo  根据 姓名专业居住地查找
  }
  onImportExcel = file => {
    // 获取上传的文件对象
    const { files } = file.target;
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        // 以二进制流方式读取得到整份excel表格对象
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = []; // 存储获取到的数据
        // 遍历每张工作表进行读取（这里默认只读取第一张表）
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            // 利用 sheet_to_json 方法将 excel 转成 json 数据
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            // break; // 如果只取第一张表，就取消注释这行
          }
        }
        this.formatData(data);
      } catch (e) {
        // 这里可以抛出文件类型错误不正确的相关提示
        console.log(e)
        console.log('文件类型不正确');
        return;
      }
    };
    // 以二进制方式打开文件
    fileReader.readAsBinaryString(files[0]);
  }

  formatData(data) {
    let arr = [];
    data.forEach((item) => {
      let obj = {
        name: item["姓名"] || '',
        gender: item["性别"] === '男' ? 1 : 0,
        profession: item["专业"] || '',
        technicalTitle: item["技术职称"] || '',
        address: item["居住地"] || '',
        groupClass: this.state.groupClass,
        careerClass: item["职业分类 "] || '',
        foreignLanguage: item["外语"] || ''
      }
      arr.push(obj)
    })
    this.setState({ spinning: true })
    Ajax.POST(`/zhongcai/roster/insertBatch`, arr)
      .then(res => {
        message.success("导入成功!", 3)
        this.componentDidMount()
        this.setState({ spinning: false }, () => {
          // window.location.reload();
        })
      })
      .catch(err => {
        message.error("导入失败!", 3)
        this.setState({ spinning: false })
      })
  }

  importData() {
    this.refs.fileInput.click()
  }

  delAll() {
    if (this.state.tableData.length === 0) {
      message.error('删除失败，暂无数据', 3);
      return false;
    }
    this.setState({ spinning: true })
    Ajax.GET(`/zhongcai/roster/delAll?groupClass=${this.state.groupClass}`)
    .then(res => {
      this.setState({ spinning: false });
      message.success('删除成功！',3)
      this.componentDidMount()
    })
    .catch(err => {
      this.setState({ spinning: false })
      message.error(err, 3);
    })
  }

  render() {

    const columns = [{
      title: '姓名',
      dataIndex: 'name',
      width: 120,
      sorter: true,
   
    }, {
      title: '性别',
      dataIndex: 'gender',
      width: 120,
      sorter: true,
     
    }, {
      title: '技术职称',
      dataIndex: 'technicalTitle',
      width: 300,
      sorter: true,
   
    },
    {
      title: '专业',
      dataIndex: 'profession',
      width: 300,
      sorter: true,
    
    },
    {
      title: '居住地',
      dataIndex: 'address',
      width: 140,
      sorter: true,
     
    },
    {
      title: '职业分类',
      dataIndex: 'careerClass',
      width: 140,
      sorter: true,
   
    },
    {
      title: '外语',
      dataIndex: 'foreignLanguage',
      width: 120,
      sorter: true,
     
    },
    {
      title: '操作',
      dataIndex: 'key', key: 'x', render: (data, record) =>
        <div>
          <Link to={
            {
              pathname: "/Home/RegisterEdit",
              state: {
                data: `${record.key}`,
                category: this.props.location.state ? this.props.location.state : '仲裁员'
              },
            }
          }
          ><Button icon="form" style={{ marginRight: "12px", outline: "none", border: "none", boxShadow: "none", marginLeft: "-16px" }} onClick={this.test.bind(this, record.key)}>修改</Button></Link>
          <Popconfirm title="确定删除" okText="确定" cancelText="取消" onConfirm={this.clear.bind(this, record.key)}>
            <Button icon="delete" style={{ marginRight: "12px", outline: "none", border: "none", boxShadow: "none" }}>删除</Button>
          </Popconfirm>
          {/* <Switch checkedChildren="显示" unCheckedChildren="不显示" defaultChecked onChange={this.onOff.bind(this,record.key)}/> */}
        </div>
    }
    ];
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div id="register">
        <div>
          <Button type="primary" icon="plus" onClick={this.rua.bind(this)}>新建</Button>
          <Button type="primary" icon="import" onClick={this.importData.bind(this)} style={{ margin: '0 40px' }}>一键导入</Button>
          <Button type="primary" icon="delete"  onClick={this.delAll.bind(this)} style={{ marginRight: '40px' }}>全部删除</Button>
          <a href={example} download>下载模板</a>
          <input type='file' ref="fileInput" accept='.xlsx, .xls' onChange={this.onImportExcel} style={{ display: 'none' }} />
          <InputGroup
            compact
            style={{ marginBottom: "31px" }}
          >
            <Select defaultValue="姓名" onChange={this.handleChange.bind(this)} className="selectBox" size='large' suffixIcon={<Icon type="caret-down" />}>
              <Option value="1" key="1">姓名</Option>
              <Option value="2" key="2">专业</Option>
              <Option value="3" key="3">居住地</Option>
            </Select>
            <Search
              placeholder="search"
              onSearch={this.onSearch.bind(this)}
              style={{ width: 330 }}
              size='large'
            />
            <Button className="buttonBox" onClick={this.showAll.bind(this, 10, 1, '', '','', true)}>显示全部</Button>
          </InputGroup>
        </div>
        <div className="info" style={{ display: this.state.selectedRowKeys.length ? "block" : "none" }}>
          <Button icon="exclamation-circle" style={{ border: "none", outline: "none", boxShadow: "none", color: "#2a8ff6", backgroundColor: "#e5f7ff" }}></Button>
          <span>已选择<span className="addNumber">{this.state.allNumber || 0}</span>项</span>
          <Popconfirm title="确定删除" okText="确定" cancelText="取消" onConfirm={this.clear.bind(this, this.state.selectedRowKeys)}>
            <span className="clearAll">删除</span>
          </Popconfirm>
        </div>
        <div>
          <div style={{ marginBottom: 16 }}>
            <span style={{ marginLeft: 8 }}>
            </span>
          </div>
          <Spin spinning={this.state.spinning}>
            <Table locale={{ emptyText: '暂无数据' }} rowSelection={rowSelection} pagination={{ total: this.state.total, current: this.state.pageNum }} onChange={this.myOnchange.bind(this)} columns={columns} dataSource={this.state.tableData} />
          </Spin>
        </div>
      </div>
    );
  }
}

export default withRouter(Recruitment);

