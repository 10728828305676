import React, { Component } from 'react'
import { Button } from 'antd'
import './index.css'
class PreviewModal extends Component {
  closeModal() {
    this.props.closePreview()
  }
  render() {
    return <div id="preview-modal">
      <div className="preview-box">        
        <div className="preview-content">
          <h3 className="title">{this.props.title}</h3>
          <p className='publish-time'>发布时间： <span>{new Date().toLocaleDateString()}</span>来源：<span className="origin">{this.props.origin}</span></p>
          <div className="line-dashed"></div>
          <div dangerouslySetInnerHTML={{ __html: this.props.showHtml}}></div>
        </div>
        <div className="preview-btm">
          <Button type="primary" onClick={this.closeModal.bind(this)}>关闭</Button>
        </div>
      </div>
    </div>
  }
} 
export default PreviewModal