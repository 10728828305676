import React, { Component } from 'react';
import Home from './components/Home/Home.js'
import Login from './components/login/index.js'
import { BrowserRouter as Router , Route} from "react-router-dom";
import cookie from './utils/utils';
import './App.css';
const getcookie = cookie.getCookie
class App extends Component {
  componentDidMount() {
    
  }
  render() {
    return (
      <div className="App">
        <Router>
          <div>
            <Route exact path="/" component={Login} />
            <Route path="/Home" component={Home} />
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
