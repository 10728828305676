const method = {
    // getcookie(key) {//获取cookie
    //     var xxx = document.cookie;
    //     var oDate = new Date();
    //     oDate.setDate(oDate.getDate() + 30);
    //     var result = [];
    //     var oName = [];
    //     var oValue = [];
    //     var rua_2 = xxx.split(";");
    //     for (var i = 0; i < rua_2.length; i++) {
    //         result.push(rua_2[i].split("="))
    //     }
    //     for (var j = 0; j < result.length; j++) {
    //         oName.push(result[j][0]);
    //         oValue.push(result[j][1]);
    //     }
    //     for (var k = 0; k < oName.length; k++) {
    //         oName[k] = oName[k].replace(" ", "");
    //     }
    //     for (var z = 0; z < oName.length; z++) {
    //         if (oName[z] === key) {
    //             return [oName[z], oValue[z]];
    //         }
    //     }
    // },
    getCookie(key) {
        var cookie = document.cookie;
        var arr = cookie.split("; ");
        for (var i = 0; i < arr.length; i++) {
            var newArr = arr[i].split("=");
            if (key == newArr[0]) {
                return newArr[1];
            }
        }
    },
    setcookie(key, value, time) {//设置cookie
        var date = new Date();
        date.setDate(date.getDate() + time);
        document.cookie = `${key}=${value};expires=` + date;
    },
    removeCookie(key) {
        method.setcookie(key, "", -1);
    }
}
export default method