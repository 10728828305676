import React from "react"
import './index.css'
import { Button , Table , Spin ,Modal , message, Input, Select, Icon} from 'antd';
import { Link } from 'react-router-dom';
import { Ajax } from "../../utils/ajax";
import example from '../../机构库导入模板.xlsx'
import * as XLSX from 'xlsx'
import cookie from '../../utils/utils'
const getCookie = cookie.getCookie
const InputGroup = Input.Group;
const Option = Select.Option;
const Search = Input.Search;
class organization extends React.Component {
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    loading: false,
    tableData: [],
    //已选择的个数
    allNum:0,
    total:0,
    pageSize: 10,
    pageNum: 1,
    spinning:false,
    visible: false,
    name:'',
    phone:'',
    content:'',
    findType: 1,
    findValue: ''
  };
  rua(){
    console.log(1111)
    console.log(this.props)
  }
  //修改
  create = (key) => {
    console.log("修改")
  }
  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  }

  start = () => {
    this.setState({ loading: true });
  // ajax request after empty completing
    setTimeout(() => {
        this.setState({
        selectedRowKeys: [],
        loading: false,
        //总数
        allNumber:0
        });
    }, 1000);
  }
  onSelectChange = (selectedRowKeys) => {
    this.setState({ 
      selectedRowKeys: selectedRowKeys,
      allNumber:selectedRowKeys.length
    });
  }

  myOnchange(pagination, filters, sorter){
    this.setState({
      spinning:true
    })
    //  http://120.26.61.246:9002/zhongcai/offer/?pageNum=2&pageSize=10
     fetch('/zhongcai/body?pageNum='+pagination.current+'&pageSize=10', {
      headers: new Headers({
        'token': getCookie('token')
      })
     }).then((res)=>{
      return res.json();
    }).then(res => {
      this.setState({
        spinning:false,
        total:res.data.pageInfo.total
      })
      let data = res.data.pageInfo.list
      let arr = []
      data.forEach((item, index) => {
        let obj = {
          key: item.id,
          post: item.name,
          number: item.className,
          content: item.websiteUrl,
          time:item.updateat
        }
        arr[index] = obj
      });
      this.setState({
        tableData: arr
      })
    }).catch((res)=>{
        console.log(res);
    });
    
  }
  onSearch(value){
    if(!value) {
      message.error('搜索内容不能为空！', 3)
      return false;
    }
    this.setState({
      findValue: value
    })
    this.showAll(10, 1, this.state.findType, value)
    //todo  根据 姓名专业居住地查找
  }

  //显示全部
  showAll(pageSize, pageNum, type = '', str = '', isAll = false){
    if(isAll) {
      this.setState({
        findValue: ''
      })
      document.querySelector('.ant-input.ant-input-lg').value = ""
    }
    let url = '/zhongcai/body/?pageSize=' + pageSize + '&pageNum=' + pageNum; 
    if(type !== '' && str !== '') {
      url = '/zhongcai/body/?pageSize=' + pageSize + '&pageNum=' + pageNum + '&findType=' + encodeURIComponent(type) + '&findStr=' + encodeURIComponent(str);
    }
    fetch(url, {
      method:"GET",
      headers: new Headers({
        'token': getCookie('token')
      })
    }).then((res)=>{
      if(res.status >= 500) {
        message.error('服务器出错，请稍后重试', 3)
        return false
      }
      return res.json();
    }).then(res => {
      if(!res) {
        return false;
      }
      let arr = []      
      let data = res.data.pageInfo.list
      data.forEach((item,index) => {
        let obj = {
          key: item.id,
          post: item.name,
          number: item.className,
          content: item.websiteUrl,
          time:item.updateat
        }
        arr[index] = obj
      });
      this.setState({
        tableData: arr,
        total: res.data.pageInfo.total,
        pageNum: res.data.pageInfo.pageNum
      }, () => {
        console.log(this.state.total)
      })
    })
  }

  
  handleChange(e){
    this.setState({
      findType: e
    })
  }

  pageChange(obj) {
    this.showAll(obj.pageSize, obj.current, this.state.findType, this.state.findValue)
  }
  //多选清空&&单清空
  clear(arr){
    this.setState({
      spinning:true
    })
    // http://120.26.61.246:9002/zhongcai/body/2
    let arrSplice = this.state.tableData
    if(typeof arr == "number"){
      fetch('/zhongcai/body/'+arr,{
        method:"DELETE",
        headers: new Headers({
          'token': getCookie('token')
        })
      }).then((res)=>{
        return res.json();
      }).then(res => {
        this.setState({
          spinning:false
        })
        arrSplice.forEach((item,index,array)=>{
          if(item.key===arr){
            array.splice(index,1)
          }
        })
        this.setState({
          tableData:arrSplice
        })
        message.info("删除成功")
        this.componentDidMount()
      }).catch((res)=>{
        message.info("删除失败")
      });
    }
    else {
      for(var i=0;i<arrSplice.length;i++){
        for(var j=0;j<arr.length;j++){
          if(arrSplice[i].key === arr[j] ){
            arrSplice.splice(i,1)
            fetch('/zhongcai/body/'+arr[j],{
              method:"DELETE",
              headers: new Headers({
                'token': getCookie('token')
              })
            }).then((res)=>{
              return res.json();
            }).then(res => {
              this.setState({
                spinning:false
              })
              message.info("删除成功")
              this.componentDidMount()
            }).catch((res)=>{
                message.info("删除失败")
            });
          }
        }
      }
    }
    
   
    this.setState({
      tableData:arrSplice
    })
  }

  componentDidMount(){
    this.setState({
      spinning:true
    })
    
     //所有列表
   // http://120.26.61.246:9002/zhongcai/body?pageSize=10
    fetch('/zhongcai/body?pageSize=10', {
      headers: new Headers({
        'token': getCookie('token')
      })
    }).then((res)=>{
      return res.json();
    }).then(res => {
      this.setState({
        spinning:false
      })
      let data = res.data.pageInfo.list
      let tableData = this.state.tableData
      
      data.forEach((item, index) => {
        let obj = {
          key: item.id,
          post: item.name,
          number: item.className,
          content: item.websiteUrl,
          time:item.updateat
        }
        tableData[index] = obj
      });
      this.setState({
        tableData,
        pageNum: res.data.pageInfo.pageNum,
        total: res.data.pageInfo.total
      })
    }).catch((res)=>{
        console.log(res);
    });
  }

  onOff(checked,key){
    console.log(checked,key)
  }

  onImportExcel = file => {
    // 获取上传的文件对象
    const { files } = file.target;
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        // 以二进制流方式读取得到整份excel表格对象
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = []; // 存储获取到的数据
        // 遍历每张工作表进行读取（这里默认只读取第一张表）
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            // 利用 sheet_to_json 方法将 excel 转成 json 数据
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            // break; // 如果只取第一张表，就取消注释这行
          }
        }
        this.formatData(data);
      } catch (e) {
        // 这里可以抛出文件类型错误不正确的相关提示
        console.log(e)
        console.log('文件类型不正确');
        return;
      }
    };
    // 以二进制方式打开文件
    fileReader.readAsBinaryString(files[0]);
  }

  formatData(data) {
    let arr = []
    data.forEach((item) => {
      let obj = {
        name: item["名称"] || '',
        className: item["类别"] || '',
        websiteUrl: item["门户网站"] || ''
      }
      arr.push(obj)
    })
    this.setState({spinning: true})
    Ajax.POST(`/zhongcai/body/insertBatch`, arr)
    .then(res => {
      message.success("导入成功!", 3)
      this.componentDidMount()
      this.setState({spinning: false})
    })
    .catch(err => {
      message.error("导入失败!", 3)
      this.setState({spinning: false})
    })
  }

  importData() {
    this.refs.fileInput.click() 
  }

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const columns = [{
      title: '名称',
      dataIndex: 'post',
    }, {
      title: '分类',
      dataIndex: 'number',
    }, {
      title: '链接',
      dataIndex: 'content',
      render: (text) => {
        if(text.indexOf('http') === -1) {
          text = "http://" + text
        }
        return <span className="col-sql"><a href={text}>{text}</a></span>
      } 
    },
    {
      title: '时间',
      dataIndex: 'time',
    },{
      title: '操作',
      dataIndex: 'key', key: 'x', render: (data, record) => 
          <div>
              <Link to={
                   {
                    //    新的地址
                    pathname:"/Home/organizationEdit", 
                    state: {data:`${record.key}`}  
                  }
              }><Button icon="form" style={{marginRight:"12px",outline: "none",border: "none",boxShadow: "none",marginLeft:"-16px"}} onClick={this.create.bind(this,record.key)}>修改</Button></Link>
              <Button icon="delete" style={{marginRight:"12px",outline: "none",border: "none",boxShadow: "none"}} onClick={this.clear.bind(this,record.key)}>删除</Button>
              {/* <Switch checkedChildren="显示" unCheckedChildren="不显示" defaultChecked onChange={this.onOff.bind(this,record.key)}/> */}
          </div>
    }
  ];
   
    return (
      <div id='organization'>
      {/* 新的地址 */}
          <Link to="/Home/organizationEdit"><Button type="primary" icon="plus" onClick={this.rua.bind(this)}>新建</Button></Link>
          <Button type="primary" icon="import" onClick={this.importData.bind(this)} style={{margin: '0 40px'}}>一键导入</Button>
          <a href={example} download>下载模板</a>
          <input type='file' ref="fileInput" accept='.xlsx, .xls' onChange={this.onImportExcel} style={{display: 'none'}}/>
          <InputGroup 
            compact
            style={{marginBottom:"31px"}}
            >
            <Select defaultValue="名称" onChange={this.handleChange.bind(this)} className="selectBox" size='large' suffixIcon={<Icon type="caret-down" />}>
              <Option value="1" key="1">名称</Option>
              <Option value="2" key="2">类别</Option>
            </Select>
            <Search
                placeholder="search"
                onSearch={this.onSearch.bind(this)}
                style={{ width: 330 }}
                size='large'
              />
            <Button className="buttonBox" onClick={this.showAll.bind(this, 10, 1, '', '', true)}>显示全部</Button>
          </InputGroup> 
          <div className="info" style={{display:this.state.selectedRowKeys.length ? "block" : "none"}}>
            <Button icon="exclamation-circle" style={{border:"none",outline:"none",boxShadow:"none",color:"#2a8ff6",backgroundColor:"#e5f7ff"}}></Button>
            <span>已选择<span className="addNumber">{this.state.allNumber || 0}</span>项</span>
            <span className="clearAll" onClick={this.clear.bind(this,this.state.selectedRowKeys)}>清空</span>
          </div>

          <div>
          <div style={{ marginBottom: 16 }}>
            <span style={{ marginLeft: 8 }}>
            </span>
            </div>
            <Spin spinning={this.state.spinning}>
              <Table locale={{emptyText: '暂无数据' }} rowSelection={rowSelection} pagination={{total:this.state.total, current: this.state.pageNum}} onChange={this.pageChange.bind(this)} columns={columns} dataSource={this.state.tableData} />
            </Spin>
          </div>
        <Modal
            title="详细信息"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            >
            <p><span style={{width:80,display:"inline-block"}}>姓名:</span>{this.state.name}</p>
            <p><span style={{width:80,display:"inline-block"}}>联系方式:</span>{this.state.phone}</p>
            <p><span style={{width:80,display:"inline-block"}}>内容:</span>{this.state.content}</p>
        </Modal>
      </div>
    );
  }
}

export default organization;

