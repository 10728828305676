import React from 'react';
import './index.css';
import { Button, Table, Spin, Modal, message, Popconfirm } from 'antd';
import cookie from '../../utils/utils';
const getcookie = cookie.getCookie;

class feedback extends React.Component {
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    loading: false,
    tableData: [],
    //已选择的个数
    allNum: 0,
    total: 0,
    spinning: false,
    visible: false,
    name: '',
    phone: '',
    content: ''
  };
  //查看
  showModal = key => {
    this.setState({
      visible: true
    });
    //http://120.26.61.246:9002/zhongcai/feedback/4
    fetch('/zhongcai/feedback/' + key, {
      method: 'GET',
      headers: new Headers({
        token: getcookie('token')
      })
    })
      .then(res => {
        return res.json();
      })
      .then(res => {
        let data = res.data.feedback;
        this.setState({
          name: data.name,
          phone: data.contactInformation,
          content: data.feedbackContent
        });
      })
      .catch(res => {
        console.log(res);
      });
    console.log(key);
  };
  handleOk = e => {
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };

  start = () => {
    this.setState({ loading: true });
    // ajax request after empty completing
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
        //总数
        allNumber: 0
      });
    }, 1000);
  };
  onSelectChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys: selectedRowKeys,
      allNumber: selectedRowKeys.length
    });
  };

  myOnchange(pagination, filters, sorter) {
    this.setState({
      spinning: true
    });
    //  http://120.26.61.246:9002/zhongcai/offer/?pageNum=2&pageSize=10
    fetch('/zhongcai/offer/?pageNum=' + pagination.current + '&pageSize=10', {
      headers: new Headers({
        token: getcookie('token')
      })
    })
      .then(res => {
        return res.json();
      })
      .then(res => {
        this.setState({
          spinning: false,
          total: res.data.pageInfo.total
        });
        let data = res.data.pageInfo.list;
        let arr = [];
        data.forEach((item, index) => {
          let obj = {
            key: item.id,
            post: item.post,
            number: item.number,
            content: item.content,
            time: item.createat
          };
          arr[index] = obj;
        });
        this.setState({
          tableData: arr
        });
      })
      .catch(res => {
        console.log(res);
      });
  }

  //多选清空&&单清空
  clear(arr) {
    this.setState({
      spinning: true
    });
    //http://120.26.61.246:9002/zhongcai/feedback/16
    let arrSplice = this.state.tableData;
    if (typeof arr == 'number') {
      fetch('/zhongcai/feedback/' + arr, {
        method: 'DELETE',
        headers: new Headers({
          token: getcookie('token')
        })
      })
        .then(res => {
          return res.json();
        })
        .then(res => {
          this.setState({
            spinning: false
          });
          arrSplice.forEach((item, index, array) => {
            if (item.key === arr) {
              array.splice(index, 1);
            }
          });
          this.setState({
            tableData: arrSplice
          });
          message.info('删除成功');
          this.componentDidMount();
        })
        .catch(res => {
          message.info('删除失败');
          console.log(res);
        });
    } else {
      for (var i = 0; i < arrSplice.length; i++) {
        for (var j = 0; j < arr.length; j++) {
          if (arrSplice[i].key === arr[j]) {
            arrSplice.splice(i, 1);
            fetch('/zhongcai/feedback/' + arr[j], {
              method: 'DELETE',
              headers: new Headers({
                token: getcookie('token')
              })
            })
              .then(res => {
                return res.json();
              })
              .then(res => {
                this.setState({
                  spinning: false
                });
                message.info('删除成功');
                this.componentDidMount();
              })
              .catch(res => {
                console.log(res);
                message.info('删除失败');
              });
          }
        }
      }
    }

    this.setState({
      tableData: arrSplice
    });
  }

  componentDidMount() {
    this.setState({
      spinning: true
    });
    this.getAllData();
  }

  getAllData = (pageNum= 0, pageSize= 10) => {
    //所有列表
    //  http://120.26.61.246:9002/zhongcai/feedback?pageSize=10
    fetch(`/zhongcai/feedback?pageSize=${pageSize}&pageNum=${pageNum}`, {
      headers: new Headers({
        token: getcookie('token')
      })
    })
      .then(res => {
        return res.json();
      })
      .then(res => {
        this.setState({
          spinning: false
        });
        let data = res.data.pageInfo.list;

        const tableData = data.map((item, index) => {
          let obj = {
            key: item.id,
            post: item.name,
            number: item.contactInformation,
            content: item.feedbackContent,
            time: item.updateat
          };
          return obj;
        });
        this.setState({
          tableData,
          total: res.data.pageInfo.total
        });
      })
      .catch(res => {
        console.log(res);
      });
  }
  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const columns = [
      {
        title: '姓名',
        dataIndex: 'post'
      },
      {
        title: '联系方式',
        dataIndex: 'number'
      },
      {
        title: '内容',
        dataIndex: 'content',
        render: text => <span className="col-sql">{text}</span>
      },
      {
        title: '时间',
        dataIndex: 'time'
      },
      {
        title: '操作',
        dataIndex: 'key',
        key: 'x',
        render: (data, record) => (
          <div>
            <Button
              icon="form"
              style={{
                marginRight: '12px',
                outline: 'none',
                border: 'none',
                boxShadow: 'none',
                marginLeft: '-16px'
              }}
              onClick={this.showModal.bind(this, record.key)}
            >
              查看
            </Button>
            <Popconfirm
              title="确定删除?"
              okText="确定"
              cancelText="取消"
              onConfirm={this.clear.bind(this, record.key)}
            >
              <Button
                icon="delete"
                style={{
                  marginRight: '12px',
                  outline: 'none',
                  border: 'none',
                  boxShadow: 'none'
                }}
              >
                删除
              </Button>
            </Popconfirm>
          </div>
        )
      }
    ];

    return (
      <div id="feedback">
        <div
          className="info"
          style={{
            display: this.state.selectedRowKeys.length ? 'block' : 'none'
          }}
        >
          <Button
            icon="exclamation-circle"
            style={{
              border: 'none',
              outline: 'none',
              boxShadow: 'none',
              color: '#2a8ff6',
              backgroundColor: '#e5f7ff'
            }}
          />
          <span>
            已选择
            <span className="addNumber">{this.state.allNumber || 0}</span>项
          </span>
          <Popconfirm
            title="确定删除?"
            okText="确定"
            cancelText="取消"
            onConfirm={this.clear.bind(this, this.state.selectedRowKeys)}
          >
            <span className="clearAll">删除</span>
          </Popconfirm>
        </div>

        <div>
          <div style={{ marginBottom: 16 }}>
            <span style={{ marginLeft: 8 }} />
          </div>
          <Spin spinning={this.state.spinning}>
            <Table
              locale={{ emptyText: '暂无数据' }}
              rowSelection={rowSelection}
              pagination={{
                total: this.state.total,
                onChange: (page, pageSize) => {
                  this.getAllData(page, pageSize);
                }
              }}
              // onChange={this.myOnchange.bind(this)} TODO: 这个接口有啥用
              columns={columns}
              dataSource={this.state.tableData}
            />
          </Spin>
        </div>
        <Modal
          title="详细信息"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText={'确定'}
          cancelText={'关闭'}
        >
          <p>
            <span style={{ width: 80, display: 'inline-block' }}>姓名:</span>
            {this.state.name}
          </p>
          <p>
            <span style={{ width: 80, display: 'inline-block' }}>
              联系方式:
            </span>
            {this.state.phone}
          </p>
          <p>
            <span style={{ width: 80, display: 'inline-block' }}>内容:</span>
            {this.state.content}
          </p>
        </Modal>
      </div>
    );
  }
}

export default feedback;
