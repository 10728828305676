import { Layout, Menu, Icon, Spin, Alert } from 'antd';
import React from "react"
import './Home.css'
import Consult from '../Consult/Consult'
import Recruitment from '../Recruitment/Recruitment'
import Register from '../Register/Register'
import { withRouter, Route, Link } from 'react-router-dom';
import ConsultEdit from "../ConsultEdit/ConsultEdit"
import RecruitmentEdit from "../RecruitmentEdit/RecruitmentEdit"
import RegisterEdit from "../RegisterEdit/RegisterEdit"
import Feedback from '../feedback/index'
import organization from '../organization/index'
import organizationEdit from '../organizationEdit/index'
import resume from '../resume/index'
import logoImg from '../../img/logo.png'
import logoImg2 from '../../img/logo2.png'
import cookie from '../../utils/utils'
import BannerSetting from '../BannerSetting'
import AddBanner from '../AddBanner'
const { Header, Content, Sider } = Layout;
const getCookie = cookie.getCookie
const removeCookie = cookie.removeCookie;
const SubMenu = Menu.SubMenu;

class SiderDemo extends React.Component {
  rootSubmenuKeys = ['sub2', 'sub10', 'sub11'];
  rootSubkey2 = ['1', '2', '3', '4', '5', '6', '7', '8']
  state = {
    openKeys: ['sub2'],
    selectedKeys: [],
    onOff: false,
    pid: "",
    sid: "",
    othopenkeys: [],
    collapsed: false,
    username: "某某管理员",
    defaultMenu: 'sub2'
  };
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }
  //根据路由更改左侧默认展开的下拉菜单项
  changeSelectedMenu() {
    let href = window.location.href;
    let hrefArr = href.split('/');
    switch (hrefArr[hrefArr.length - 1]) {
      case "ConsultEdit":
        {
          this.setState({
            openKeys: ['sub2']
          })
          break;
        }
      case "Recruitment":
        {
          this.setState({
            openKeys: ['sub10']
          })
          break;
        }
      case "RecruitmentEdit":
        {
          this.setState({
            openKeys: ['sub10']
          })
          break;
        }
      case "resume":
        {
          this.setState({
            openKeys: ['sub10']
          })
          break;
        }
      case "Register":
        {
          this.setState({
            openKeys: ['sub11']
          })
          break;
        }
      case "organization":
        {
          this.setState({
            openKeys: ['sub11']
          })
          break;
        }
      case "RegisterEdit":
        {
          this.setState({
            openKeys: ['sub11']
          })
          break;
        }
      case "organizationEdit":
        {
          this.setState({
            openKeys: ['sub11']
          })
          break;
        }
      default:
        break;
    }
  }
  componentDidMount() {
    this.changeSelectedMenu()
    let selectedKey = sessionStorage.getItem('selectedKey')
    let openKey = sessionStorage.getItem('openKeys')
    openKey && this.setState({
      openKeys: openKey.split(',')
    })
    if (selectedKey) {
      this.setState({
        selectedKeys: [selectedKey]
      })
      if (parseInt(selectedKey) === 35 || parseInt(selectedKey) === 53) {
        this.setState({
          openKeys: []
        })
      }
    }

    if (this.props.location.query) {
      this.setState({
        username: this.props.location.query.username
      })
      setTimeout(() => {
        if (getCookie("islogin")) {
          let islogin = getCookie("islogin")
          if (islogin !== 'true') {
            this.props.history.push({
              pathname: "/Home/Consult"
            })
          }
        } else {
          this.props.history.push({
            pathname: "/Home/Consult"
          })
        }
      }, 0)
    } else {
      this.setState({
        username: getCookie("username") && getCookie("username")
      })
    }
    if (!this.refs['toIE9'].props.style) {
      return;
    }
    //ie9兼容&&heightall
    this.refs['toIE9'].props.style.height = "auto"
    var browser = navigator.appName
    var b_version = navigator.appVersion
    var version = b_version.split(";");
    if (version[1]) {
      var trim_Version = version[1].replace(/[ ]/g, "");
      if (browser === "Microsoft Internet Explorer") {
        this.refs['toIE9'].props.style.height = "957px"
      }
      if (browser === "Microsoft Internet Explorer" && trim_Version === "MSIE9.0") {
        this.refs['toIE9'].props.style.float = "left"
        this.refs['toIE9'].props.style.height = document.documentElement.clientHeight
      }
    }

  }

  backToLogin() {
    removeCookie('token');
    removeCookie('username');
    removeCookie('islogin');
    this.props.history.push({
      pathname: "/"
    })
  }

  //点一个下来其他回去
  onOpenChange = (openKeys) => {
    sessionStorage.removeItem('selectedKey')
    this.setState({
      selectedKeys: []
    })
    if (openKeys.indexOf('37') !== -1 || openKeys.indexOf('38') !== -1 || openKeys.indexOf('39') !== -1) {

    } else {
      if (openKeys.length === 3) {//点击收缩二级子菜单
        openKeys.splice(1, 1)
      }
      let filterArr = openKeys.filter((item) => item.indexOf('sub') !== -1)
      if (filterArr.length === 2) {
        openKeys.splice(0, 1)
      }
    }

    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    //当前选中项
    // if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
    this.setState({ openKeys });
    // } else {
    //   this.setState({
    //     openKeys: latestOpenKey ? [latestOpenKey] : [],
    //   });
    // }
    openKeys.forEach((item) => {
      let rua = parseInt(item)
      this.props.history.push({
        pathname: "/Home/Consult",
        state: {
          pid: rua || ''
        }
      })
    })
    sessionStorage.setItem('openKeys', openKeys)
    let arr = this.rootSubmenuKeys
    arr.forEach(item => {
      if (item === latestOpenKey) {
        if (latestOpenKey === 'sub2') {
          // this.props.history.push({
          //   pathname:"/Home/Consult"
          // })
        } else if (latestOpenKey === 'sub11') {
          this.props.history.push({
            pathname: "/Home/Register",
            mytitle: ""
          })
        } else if (latestOpenKey === 'sub10') {
          this.props.history.push({
            pathname: "/Home/Recruitment"
          })
        }
      }
    });
  }
  onSelect(item) {
    if (parseInt(item.key) === 35 || parseInt(item.key) === 53) {
      this.setState({
        openKeys: []
      })
    }
    // if(parseInt(item.key) === 29) {
    //   this.setState({
    //     openKeys: ['sub2']
    //   })
    //   sessionStorage.setItem('openKeys', ['sub2'])
    // }
    //将当前选中的菜单项存入session
    sessionStorage.setItem('selectedKey', item.key);
    this.setState({
      selectedKeys: [item.key]
    })
    window.localStorage.pid = item.item.props.pid
    window.localStorage.sid = item.item.props.sid
  }
  readerItem() {
    return <Spin tip="Loading...">
      <Alert
        message="Alert message title"
        description="Further details about the context of this alert."
        type="info"
      />
    </Spin>
  }

  render() {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        {/* loading..... */}
        {/* {this.state.onOff?this.readerItem():''} */}
        <Sider
          trigger={null}
          width='256px'
          collapsible
          collapsed={this.state.collapsed}
          ref="toIE9"
        >
          <div className="logo">
            <img src={this.state.collapsed ? logoImg2 : logoImg} alt='' style={{ width: "85%", marginTop: "50px", marginBottom: "20px" }} />
          </div>
          <Menu
            openKeys={this.state.openKeys}
            onOpenChange={this.onOpenChange.bind(this)}
            theme="dark"
            mode="inline"
            selectedKeys={this.state.selectedKeys}
            onSelect={this.onSelect.bind(this)}
          >
            <SubMenu key="sub2" title={<span><Icon type="file-text" /><span>资讯管理</span></span>} style={{ textAlign: "left" }}>
              <SubMenu key="1" sid='' pid='1' title='长仲风采'>
                <Menu.Item key="1" pid="1" sid='9'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 9,
                      pid: 1
                    }
                  }
                }>关于我们</Link></Menu.Item>
                {/* <Menu.Item key="2" pid="1" sid='10'>
                  <Link to={
                    {
                      pathname:"/Home/Consult",
                      state: {
                        sid:10,
                        pid:1
                      }
                    }
                  }>发展历程</Link> */}
                <SubMenu key="37" sid='38' pid='1' title='发展历程'>
                  <Menu.Item key="36" pid="1" sid='38'><Link to={
                    {
                      pathname: "/Home/Consult",
                      state: {
                        sid: 38,
                        pid: 1
                      }
                    }
                  }>大事纪</Link></Menu.Item>
                  <Menu.Item key="37" pid="1" sid='11'><Link to={
                    {
                      pathname: "/Home/Consult",
                      state: {
                        sid: 39,
                        pid: 1
                      }
                    }
                  }>工作报告</Link></Menu.Item>
                </SubMenu>
                {/* </Menu.Item> */}
                <Menu.Item key="3" pid="1" sid='11'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 11,
                      pid: 1
                    }
                  }
                }>组织机构</Link></Menu.Item>
                <Menu.Item key="4" pid="1" sid='12'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 12,
                      pid: 1
                    }
                  }
                }>委员会成员</Link></Menu.Item>
                {/* <Menu.Item key="5" pid="1" sid='13'><Link to={
                    {
                      pathname:"/Home/Consult",
                      state: {
                        sid:13,
                        pid:1
                      }
                    }
                  }>专门委员会</Link></Menu.Item> */}
                <SubMenu key="38" sid='38' pid='1' title='专门委员会'>
                  <Menu.Item key="39" pid="1" sid='40'><Link to={
                    {
                      pathname: "/Home/Consult",
                      state: {
                        sid: 40,
                        pid: 1
                      }
                    }
                  }>道德纪律委员会</Link></Menu.Item>
                  <Menu.Item key="40" pid="1" sid='41'><Link to={
                    {
                      pathname: "/Home/Consult",
                      state: {
                        sid: 41,
                        pid: 1
                      }
                    }
                  }>专家咨询委员会</Link></Menu.Item>
                  <Menu.Item key="41" pid="1" sid='42'><Link to={
                    {
                      pathname: "/Home/Consult",
                      state: {
                        sid: 42,
                        pid: 1
                      }
                    }
                  }>发展委员会</Link></Menu.Item>
                </SubMenu>
                <Menu.Item key="6" pid="1" sid='14'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 14,
                      pid: 1
                    }
                  }
                }>长仲文化</Link></Menu.Item>
                <Menu.Item key="7" pid="1" sid='15'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 15,
                      pid: 1
                    }
                  }
                }>仲裁资讯</Link></Menu.Item>
                <Menu.Item key="29" pid='1' sid='37'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 37,
                      pid: 1
                    }
                  }
                }>长仲公告</Link></Menu.Item>
              </SubMenu>
              <SubMenu key="2" title="仲裁指南">
                <Menu.Item key="8" pid='2' sid='16'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 16,
                      pid: 2
                    }
                  }
                }>当事人仲裁指南</Link></Menu.Item>
                <Menu.Item key="9" pid='2' sid='17'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 17,
                      pid: 2
                    }
                  }
                }>仲裁规则</Link></Menu.Item>
                <Menu.Item key="10" pid='2' sid='18'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 18,
                      pid: 2
                    }
                  }
                }>仲裁流程</Link></Menu.Item>
                <Menu.Item key="11" pid='2' sid='19'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 19,
                      pid: 2
                    }
                  }
                }>仲裁收费</Link></Menu.Item>
                <Menu.Item key="12" pid='2' sid='20'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 20,
                      pid: 2
                    }
                  }
                }>文书下载</Link></Menu.Item>
                <Menu.Item key="13" pid='2' sid='21'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 21,
                      pid: 2
                    }
                  }
                }>常见问题</Link></Menu.Item>
              </SubMenu>
              <SubMenu key="3" title="仲裁员">
                <Menu.Item key="14" pid="3" sid='22'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 22,
                      pid: 3
                    }
                  }
                }>仲裁员管理规范</Link></Menu.Item>
                <Menu.Item key="15" pid='3' sid='23'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 23,
                      pid: 3
                    }
                  }
                }>服务仲裁员</Link></Menu.Item>
                <Menu.Item key="16" pid='3' sid='24'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 24,
                      pid: 3
                    }
                  }
                }>仲裁员风采</Link></Menu.Item>
                <Menu.Item key="17" pid='3' sid='25'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 25,
                      pid: 3
                    }
                  }
                }>仲裁员培训</Link></Menu.Item>
                <Menu.Item key="50" pid='3' sid='25'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 46,
                      pid: 3
                    }
                  }
                }>申请仲裁员</Link></Menu.Item>
              </SubMenu>
              <SubMenu key="4" title="在线服务">
                <Menu.Item key="18" pid='4' sid='26'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 26,
                      pid: 4
                    }
                  }
                }>申请仲裁指南</Link></Menu.Item>
              </SubMenu>
              <SubMenu key="5" title="仲裁鉴定">
                <Menu.Item key="19" pid='5' sid='27'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 27,
                      pid: 5
                    }
                  }
                }>鉴定工作管理制度</Link></Menu.Item>
                <Menu.Item key="20" pid='5' sid='28'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 28,
                      pid: 5
                    }
                  }
                }>鉴定工作指引</Link></Menu.Item>
                <Menu.Item key="47" pid='5' sid='28'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 47,
                      pid: 5
                    }
                  }
                }>仲裁鉴定机构申报</Link></Menu.Item>
                <Menu.Item key="49" pid='5' sid='48'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 48,
                      pid: 5
                    }
                  }
                }>仲裁鉴定机构年审</Link></Menu.Item>
              </SubMenu>
              <SubMenu key="6" title="仲裁资料">
                <Menu.Item key="21" pid='6' sid='29'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 29,
                      pid: 6
                    }
                  }
                }>法律法规</Link></Menu.Item>
                <Menu.Item key="22" pid='6' sid='30'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 30,
                      pid: 6
                    }
                  }
                }>案例专栏</Link></Menu.Item>
                <Menu.Item key="23" pid='6' sid='31'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 31,
                      pid: 6
                    }
                  }
                }>仲裁研究</Link></Menu.Item>
                <Menu.Item key="24" pid='6' sid='32'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 32,
                      pid: 6
                    }
                  }
                }>电子期刊</Link></Menu.Item>
                <Menu.Item key="25" pid='6' sid='33'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 33,
                      pid: 6
                    }
                  }
                }>历史资料</Link></Menu.Item>
              </SubMenu>
              <SubMenu key="7" title="分支机构">
                <SubMenu key="39" sid='38' pid='1' title='金融仲裁院'>
                  <Menu.Item key="43" pid="1" sid='40'><Link to={
                    {
                      pathname: "/Home/Consult",
                      state: {
                        sid: 43,
                        pid: 7
                      }
                    }
                  }>金融仲裁院概况</Link></Menu.Item>
                  <Menu.Item key="44" pid="1" sid='41'><Link to={
                    {
                      pathname: "/Home/Consult",
                      state: {
                        sid: 44,
                        pid: 7
                      }
                    }
                  }>金融仲裁规则</Link></Menu.Item>
                  <Menu.Item key="45" pid="1" sid='42'><Link to={
                    {
                      pathname: "/Home/Consult",
                      state: {
                        sid: 45,
                        pid: 7
                      }
                    }
                  }>办案流程及指南</Link></Menu.Item>
                </SubMenu>
                <Menu.Item key="51"><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 50,
                      pid: 7
                    }
                  }
                }>影视文化仲裁院</Link></Menu.Item>
              </SubMenu>
              <SubMenu key="8" title="基层党建">
                <Menu.Item key="27" pid='8' sid='35'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 35,
                      pid: 8
                    }
                  }
                }>秘书处党支部介绍</Link></Menu.Item>
                <Menu.Item key="28" pid='8' sid='36'><Link to={
                  {
                    pathname: "/Home/Consult",
                    state: {
                      sid: 36,
                      pid: 8
                    }
                  }
                }>基层党建工作状态</Link></Menu.Item>
              </SubMenu>
              {/* <Menu.Item key="29" pid='1' sid='37'><Link to={
                    {
                      pathname:"/Home/Consult",
                      state: {
                        sid:37,
                        pid:1
                      }
                    }
                  }>长仲公告</Link></Menu.Item> */}
            </SubMenu>

            <SubMenu key='sub10' title={<span><Icon type="usergroup-add" /><span>招聘管理</span></span>} style={{ textAlign: "left" }}>
              <Menu.Item key="37" style={{ textAlign: "left", boxSizing: 'border-box' }}><Link to={
                {
                  pathname: "/Home/Recruitment"
                }
              }><Icon type="user-add" /><span style={{ display: this.state.collapsed ? "none" : "inline" }}></span>招聘启示</Link></Menu.Item>
              <Menu.Item key="36" style={{ textAlign: "left", boxSizing: 'border-box' }}><Link to={
                {
                  pathname: "/Home/resume"
                }
              }><Icon type="audit" /><span style={{ display: this.state.collapsed ? "none" : "inline" }}></span>简历查看</Link></Menu.Item>
              <Menu.Item key="48" pid="1" sid='49'><Link to={
                {
                  pathname: "/Home/Consult",
                  state: {
                    sid: 49,
                    pid: 1
                  }
                }
              }>招聘公告</Link></Menu.Item>
            </SubMenu>

            <SubMenu key="sub11" title={<span><Icon type="user" /><span>名册管理</span></span>} style={{ textAlign: "left" }}>
              <Menu.Item key="31"><Link to={
                {
                  pathname: "/Home/Register",
                  state: {
                    mytitle: '金融仲裁员'
                  }
                }
              }>金融仲裁员推荐名册</Link></Menu.Item>
              <Menu.Item key="33"><Link to={
                {
                  pathname: "/Home/Register",
                  state: {
                    mytitle: '仲裁员'
                  }
                }
              }>仲裁员名册</Link></Menu.Item>
              {/* <Menu.Item key="32"><Link to={
                {
                  pathname:"/Home/Register",
                  state: {
                    mytitle:'首席专家'
                  }
                }
              }>首席专家库</Link></Menu.Item> */}

              <Menu.Item key="34"><Link to={
                {
                  pathname: "/Home/organization"
                }
              }>仲裁鉴定机构库</Link></Menu.Item>
            </SubMenu>

            <Menu.Item key="35" style={{ textAlign: "left", boxSizing: 'border-box' }}><Link to={
              {
                pathname: "/Home/feedback"
              }
            }><Icon type="message" /><span style={{ display: this.state.collapsed ? "block" : "inline" }}></span>反馈投诉</Link></Menu.Item>

            <Menu.Item key="52" style={{ textAlign: "left", boxSizing: 'border-box' }}><Link to={
              {
                pathname: "/Home/bannerSetting"
              }
            }><Icon type="picture" /><span style={{ display: this.state.collapsed ? "block" : "inline" }}></span>Banner管理</Link></Menu.Item>
          </Menu>
        </Sider>
        <Layout style={{ textAlign: "left" }}>
          <Header style={{ background: '#fff', padding: "0 24px" }} className="header">
            <Icon
              className="trigger"
              type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
              onClick={this.toggle}
            />
            <div>后台管理系统</div>
            <div ><span onClick={this.backToLogin.bind(this)}>退出</span></div>
            <div>{this.state.username}</div>
          </Header>
          <Content style={{ margin: '24px 16px', padding: 24, background: '#fff', minHeight: 280 }}>
            <Route exact path="/Home" component={Consult} />
            <Route path="/Home/Consult" component={Consult} />
            <Route path="/Home/Recruitment" component={Recruitment} />
            <Route path="/Home/Register" component={Register} />
            <Route path="/Home/ConsultEdit" component={ConsultEdit} />
            <Route path="/Home/RecruitmentEdit" component={RecruitmentEdit} />
            <Route path="/Home/RegisterEdit" component={RegisterEdit} />
            <Route path="/Home/Feedback" component={Feedback} />
            <Route path="/Home/organization" component={organization} />
            <Route path="/Home/organizationEdit" component={organizationEdit} />
            <Route path="/Home/resume" component={resume} />
            <Route path="/Home/bannerSetting" component={BannerSetting} />
            <Route path="/Home/addBanner" component={AddBanner} />
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default withRouter(SiderDemo);
