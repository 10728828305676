import { message } from 'antd'
import cookie from './utils'
const getCookie = cookie.getCookie
export const Ajax = {
  /**
   * 封装fetch的get请求方法
   */
  GET: (url) => {
    return new Promise((reslove, reject) => {
      fetch(url, {
        method: 'GET',
        headers: new Headers({
          'token': getCookie('token')
        })
      })
      .then(res => {
        if(res.status >= 500) {
          message.error("服务器出错啦", 3)
          reject("服务器出错啦")
          return false
        }
        if(res.status >= 400 && res.status < 500) {
          message.error("请求参数错误", 3)
          reject("请求参数错误")
          return false
        }
        return res.json()
      })
      .then(res => {
        if(!res) {
          return false
        }
        if(res.errno !== 0) { 
          message.error(res.errmsg, 3)
          reject(res.errmsg)
          return false
        }
        reslove(res)
      })
      .catch(err => {
        message.error(err, 3)
        reject(err)
      })
    })
  },
  DELETE: (url) => {
    return new Promise((reslove, reject) => {
      fetch(url, {
        method: 'DELETE',
        headers: new Headers({
          'token': getCookie('token')
        })
      })
      .then(res => {
        if(res.status >= 500) {
          message.error("服务器出错啦", 3)
          reject("服务器出错啦")
          return false
        }
        if(res.status >= 400 && res.status < 500) {
          message.error("请求参数错误", 3)
          reject("请求参数错误")
          return false
        }
        return res.json()
      })
      .then(res => {
        if(!res) {
          return false
        }
        if(res.errno !== 0) { 
          message.error(res.errmsg, 3)
          reject(res.errmsg)
          return false
        }
        reslove(res)
      })
      .catch(err => {
        message.error(err, 3)
        reject(err)
      })
    })
  },
  /**
   * 封装fetch的post请求方法
   */
  POST: (url, data) => {
    return new Promise((reslove, reject) => {
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'token': getCookie('token')
        }
      })
      .then(res => {
        if(res.status >= 500) {
          message.error("服务器出错啦", 3)
          reject("服务器出错啦")
          return false
        }
        if(res.status >= 400 && res.status < 500) {
          message.error("请求参数错误", 3)
          reject("请求参数错误")
          return false
        }
        return res.json()
      })
      .then(res => {
        if(!res) {
          return false
        }
        if(res.errno !== 0) {
          message.error(res.errmsg, 3)
          reject(res.errmsg)
          return false
        }
        reslove(res)
      })
      .catch(err => {
        message.error(err, 3)
        reject(err)
      })
    })
  },
  /**
   * 封装fetch的put请求方法
   */
  PUT: (url, data) => {
    return new Promise((reslove, reject) => {
      fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'token': getCookie('token')
        }
      })
      .then(res => {
        if(res.status >= 500) {
          message.error("服务器出错啦", 3)
          reject("服务器出错啦")
          return false
        }
        if(res.status >= 400 && res.status < 500) {
          message.error("请求参数错误", 3)
          reject("请求参数错误")
          return false
        }
        return res.json()
      })
      .then(res => {
        if(!res) {
          return false
        }
        if(res.errno !== 0) {
          message.error(res.errmsg, 3)
          reject(res.errmsg)
          return false
        }
        reslove(res)
      })
      .catch(err => {
        message.error(err, 3)
        reject(err)
      })
    })
  }
}

