
import React, { Component } from 'react';
import { Input, Button, Select, Spin, message } from 'antd'
import 'whatwg-fetch'
import 'es6-promise'
import cookie from '../../utils/utils'
const getCookie = cookie.getCookie

const Option = Select.Option;

class RegisterEidt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classify: "",
      title: "",
      myId: '',
      spinning: false,
      sex: "男",
      status: ''
    }
  }
  componentDidMount() {
    console.log(this.props.location)
    //修改的代码  后面要判断是否为空  新建  todo
    //判断是否带值传过来
    if (this.props.location.state.data) {
      this.setState({
        spinning: true
      })
      let myId = this.props.location.state.data
      this.setState({
        myId: myId
      })
      // http://120.26.61.246:9002/zhongcai/roster/2
      fetch('/zhongcai/roster/' + myId, {
        method: "GET",
        headers: new Headers({
          'token': getCookie('token')
        })
      }).then((res) => {
        return res.json();
      }).then(res => {
        this.setState({
          spinning: false
        })
        let data = res.data.roster;
        // this.refs['sex'].rcSelect.topCtrlRef.innerText = data.gender=0?"女":"男"
        this.setState({
          sex: data.gender === 0 ? "女" : "男",
          status: data.status
        })
        this.refs["title"].input.value = data.name;
        this.refs["technicalTitle"].input.value = data.technicalTitle
        this.refs["profession"].input.value = data.profession
        this.refs["address"].input.value = data.address
        this.refs["career"].input.value = data.careerClass
        this.refs["language"].input.value = data.foreignLanguage
      }).catch((res) => {
        console.log(res);
      });
    }


  }
  rua() {


    if (this.refs["title"].input.value === "") {
      message.info("姓名不能为空")
      return
    } else if (this.refs["technicalTitle"].input.value === "") {
      message.info("技术职称不能为空")
      return
    } else if (this.refs["profession"].input.value === "") {
      message.info("专业不能为空")
      return
    } else if (this.refs["address"].input.value === "") {
      message.info("居住地不能为空")
      return
    } else if (this.refs["career"].input.value === "") {
      message.info("职业分类不能为空")
      return
    } else if (this.refs["language"].input.value === "") {
      message.info("语言不能为空")
      return
    }


    this.setState({
      spinning: true
    })
    let sex;
    if (this.state.sex === "男") {
      sex = 1
    } else {
      sex = 0
    }
    
    let json = {
      "address": this.refs["address"].input.value,
      "careerClass": this.refs["career"].input.value,
      "foreignLanguage": this.refs["language"].input.value,
      "createuser": 0,
      "gender": sex,
      "groupClass": this.props.location.state.category ? this.props.location.state.category.mytitle : this.props.location.state.category,
      "id": this.state.myId,
      "name": this.refs["title"].input.value,
      "profession": this.refs["profession"].input.value,
      "technicalTitle": this.refs["technicalTitle"].input.value,
      "updateuser": 3
    }

    console.log(json)
    //修改
    if (this.props.location.state.data) {
      //修改接口
      // http://120.26.61.246:9002/zhongcai/roster/
      fetch('/zhongcai/roster/', {
        method: "PUT",
        body: JSON.stringify(json),
        headers: new Headers({
          'Content-Type': 'application/json',
          'token': getCookie('token')
        })
      }).then((res) => {
        return res.json();
      }).then(res => {
        this.setState({
          spinning: false
        })
        message.info("修改成功")
        window.history.back();
      }).catch((res) => {
        console.log(res);
        message.info("修改失败")
      });
    } else {
      // http://120.26.61.246:9002/zhongcai/roster/
      fetch('/zhongcai/roster/', {
        method: "POST",
        body: JSON.stringify(json),
        headers: new Headers({
          'Content-Type': 'application/json',
          'token': getCookie('token')
        })
      }).then((res) => {
        return res.json();
      }).then(res => {
        this.setState({
          spinning: false
        })
        message.info("新建成功")
        window.history.back();
      }).catch((res) => {
        console.log(res);
        message.info("新建失败")
      });
    }




  }
  back() {
    window.history.back();
  }
  handleChange(e) {
    this.setState({
      sex: e
    })
  }
  render() {

    return (
      <Spin spinning={this.state.spinning}>
        <div className="App">
          <p style={{ textAlign: "left" }}>姓名</p>
          <Input placeholder="姓名" size="large" id="ruarua" ref="title" style={{ width: "250px", float: "left", marginBottom: "10px" }}></Input>
          <div style={{ clear: "both" }}></div>

          <p style={{ textAlign: "left" }}>性别</p>
          <Select
            size='large'
            value={this.state.sex}
            style={{ width: '250px', float: "left", marginBottom: "10px" }}
            onChange={this.handleChange.bind(this)}
            placeholder="性别"
          >
            <Option value="男">男</Option>
            <Option value="女">女</Option>
          </Select>
          <div style={{ clear: "both" }}></div>
          <p style={{ textAlign: "left" }}>技术职称</p>
          <Input placeholder="技术职称" size="large" ref="technicalTitle" style={{ width: "250px", float: "left", marginBottom: "10px" }}></Input>
          <div style={{ clear: "both" }}></div>
          <p style={{ textAlign: "left" }}>专业</p>
          <Input placeholder="专业" size="large" ref="profession" style={{ width: "400px", float: "left", marginBottom: "10px" }}></Input>
          <div style={{ clear: "both" }}></div>
          <p style={{ textAlign: "left" }}>居住地</p>
          <Input placeholder="居住地" size="large" ref='address' style={{ width: "250px", float: "left", marginBottom: "10px" }}></Input>
          <div style={{ clear: "both" }}></div>
          <p style={{ textAlign: "left" }}>职业分类</p>
          <Input placeholder="职业分类" size="large" ref='career' style={{ width: "400px", float: "left", marginBottom: "10px" }}></Input>
          <div style={{ clear: "both" }}></div>
          <p style={{ textAlign: "left" }}>外语</p>
          <Input placeholder="外语" size="large" ref='language' style={{ width: "400px", float: "left", marginBottom: "10px" }}></Input>
          <div style={{ clear: "both" }}></div>
          <Button onClick={this.back.bind(this)} style={{ marginTop: '36px', float: "right", background: "#004894", color: "#fff", height: "45px", width: "120px", fontSize: "17px" }} icon='backward'>返回</Button>
          <Button onClick={this.rua.bind(this)} style={{ marginTop: '36px', float: "right", marginRight: "36px", background: "#E50012", color: "#fff", height: "45px", width: "120px", fontSize: "17px" }} icon="upload">发布</Button>
        </div>
      </Spin>
    );
  }
}

export default RegisterEidt;