
import React, { Component } from 'react';
import {Input , Button ,Spin ,message} from 'antd'
import 'whatwg-fetch'
import 'es6-promise'
import cookie from '../../utils/utils'
const getCookie = cookie.getCookie


class organizationEdit extends Component{
  state={
    classify:"",
    title:"",
    myId:'',
    spinning:false
  }
  componentDidMount(){
    //修改的代码  后面要判断是否为空  新建  todo
    //判断是否带值传过来
    if(this.props.location.state){
      this.setState({
        spinning:true
      })
      let myId = this.props.location.state.data
      this.setState({
        myId:myId
      })
      //http://120.26.61.246:9002/zhongcai/body/3
      fetch('/zhongcai/body/'+myId,{
        method:"GET",
        headers: new Headers({
          'token': getCookie('token')
        })
      }).then((res)=>{
        return res.json();
      }).then(res => {
        this.setState({
          spinning:false
        })
        let data = res.data.result
        this.refs["name"].input.value = data.name
        this.refs["career"].input.value = data.className
        this.refs["link"].input.value = data.websiteUrl
      }).catch((res)=>{
          message.info("网络错误")
      });
    }

    
  }
  rua(){

    if(this.refs["name"].input.value === ""){
      message.info("姓名不能为空")
      return 
    }else if(this.refs["career"].input.value === ""){
      message.info("分类不能为空")
      return
    }else if(this.refs["link"].input.value === ""){
      message.info("链接地址不能为空")
      return
    }


    this.setState({
      spinning:true
    })
    let json = {
      "className": this.refs["career"].input.value,
      "id": 0,
      "name": this.refs["name"].input.value,
      "websiteUrl": this.refs["link"].input.value
    }
    //修改
    if(this.props.location.state){
       json.id = this.props.location.state.data
      //修改接口
      // http://120.26.61.246:9002/zhongcai/body
      fetch('/zhongcai/body/',{
        method:"PUT",
        body: JSON.stringify(json),
        headers: new Headers({
          'Content-Type': 'application/json',
          'token': getCookie('token')
        })
      }).then((res)=>{
        return res.json();
      }).then(res => {
        this.setState({
          spinning:false
        })
        message.info("修改成功")
        window.history.back();
      }).catch((res)=>{
        message.info("修改失败")
      });
    }else {
      //新建
      // http://120.26.61.246:9002/zhongcai/body
      fetch('/zhongcai/body/',{
        method:"POST",
        body: JSON.stringify(json),
        headers: new Headers({
          'Content-Type': 'application/json',
          'token': getCookie('token')
        })
      }).then((res)=>{
        return res.json();
      }).then(res => {
        this.setState({
          spinning:false
        })
        message.info("新建成功")
        window.history.back();
      }).catch((res)=>{
        message.info("新建失败")
      });
    }
    



  }
  back(){
    window.history.back();
  }
  handleChange(e) {
    this.setState({
      sex: e
    })
  }
  render() {
    
    return (
      <Spin spinning={this.state.spinning}>
        <div className="App">
          <p style={{textAlign:"left"}}>名称</p>
          <Input placeholder="名称" size="large" id="ruarua" ref="name" style={{width:"250px",float:"left",marginBottom:"10px"}}></Input>
          <div style={{clear:"both"}}></div>
          <p style={{textAlign:"left"}}>分类</p>
          <Input placeholder="分类" size="large" ref='career' style={{width:"400px",float:"left",marginBottom:"10px"}}></Input>
          <div style={{clear:"both"}}></div>
          <p style={{textAlign:"left"}}>链接</p>
          <Input placeholder="链接" size="large" ref='link' style={{width:"400px",float:"left",marginBottom:"10px"}}></Input>
          <div style={{clear:"both"}}></div>
          <Button onClick={this.back.bind(this)} style={{marginTop:'36px',float:"right",background:"#004894",color:"#fff",height:"45px",width:"120px",fontSize:"17px"}} icon='backward'>返回</Button>
          <Button onClick={this.rua.bind(this)} style={{marginTop:'36px',float:"right",marginRight:"36px",background:"#E50012",color:"#fff",height:"45px",width:"120px",fontSize:"17px"}} icon="upload">发布</Button>
        </div>
      </Spin>
    );
  }
}

export default organizationEdit;