import React from "react"
import './Recruitment.css'
import { Button , Table , Spin , message, Popconfirm} from 'antd';
import { Link } from "react-router-dom";
import cookie from '../../utils/utils'
const getCookie = cookie.getCookie

class Recruitment extends React.Component {
  rua(){
    console.log(1111)
    console.log(this.props)
  }
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    loading: false,
    tableData: [],
    //已选择的个数
    allNum:0,
    total:0,
    spinning:false
  };

  start = () => {
    this.setState({ loading: true });
  // ajax request after empty completing
    setTimeout(() => {
        this.setState({
        selectedRowKeys: [],
        loading: false,
        //总数
        allNumber:0
        });
    }, 1000);
  }
  onSelectChange = (selectedRowKeys) => {
    this.setState({ 
      selectedRowKeys: selectedRowKeys,
      allNumber:selectedRowKeys.length
    });
  }
  amend(key) {
    //修改传值
    this.setState({
      transmitId:key
    })
  }

  myOnchange(pagination, filters, sorter){
    this.setState({
      spinning:true
    })
    //  http://120.26.61.246:9002/zhongcai/offer/?pageNum=2&pageSize=10
     fetch('/zhongcai/offer/?pageNum='+pagination.current+'&pageSize=10', {
      headers: new Headers({
        'token': getCookie('token')
      })
     }).then((res)=>{
      return res.json();
    }).then(res => {
      this.setState({
        spinning:false,
        total:res.data.pageInfo.total
      })
      let data = res.data.pageInfo.list
      let arr = []
      data.forEach((item, index) => {
        let obj = {
          key: item.id,
          post: item.post,
          number: item.number,
          description: item.description,
          time:item.createat
        }
        arr[index] = obj
      });
      this.setState({
        tableData:arr
      })
    }).catch((res)=>{
        console.log(res);
    });
    
  }

  //多选清空&&单清空
  clear(arr){
    this.setState({
      spinning:true
    })
    //http://120.26.61.246:9002/zhongcai/offer/1
    let arrSplice = this.state.tableData
    if(typeof arr == "number"){
      fetch('/zhongcai/offer/'+arr,{
        method:"DELETE",
        headers: new Headers({
          'token': getCookie('token')
        })
      }).then((res)=>{
        return res.json();
      }).then(res => {
        this.setState({
          spinning:false
        })
        arrSplice.forEach((item,index,array)=>{
          if(item.key===arr){
            array.splice(index,1)
          }
        })
        this.setState({
          tableData:arrSplice
        })
        message.info("删除成功")
        this.componentDidMount()
      }).catch((res)=>{
          console.log(res)
          message.info("删除失败")
      });
    }
    else {
      for(var i=0;i<arrSplice.length;i++){
        for(var j=0;j<arr.length;j++){
          if(arrSplice[i].key === arr[j] ){
            arrSplice.splice(i,1)
            fetch('/zhongcai/offer/'+arr[j],{
              method:"DELETE",
              headers: new Headers({
                'token': getCookie('token')
              })
            }).then((res)=>{
              return res.json();
            }).then(res => {
              this.setState({
                spinning:false
              })
              message.info("删除成功")
              this.componentDidMount()
            }).catch((res)=>{
              message.info("删除失败")
                console.log(res);
            });
          }
        }
      }
    }
    
   
    this.setState({
      tableData:arrSplice
    })
  }
  componentDidMount(){
    this.setState({
      spinning:true
    })
     //所有列表
    //  http://120.26.61.246:9002/zhongcai/offer/?pageSize=10
    fetch('/zhongcai/offer/?pageSize=10', {
      headers: new Headers({
        'token': getCookie('token')
      })
    }).then((res)=>{
      return res.json();
    }).then(res => {
      this.setState({
        spinning:false
      })
      let data = res.data.pageInfo.list
      let tableData = this.state.tableData
      
      data.forEach((item, index) => {
        let obj = {
          key: item.id,
          post: item.post,
          number: item.number,
          description: item.description,
          time:item.createat
        }
        tableData[index] = obj
      });
      this.setState({
        tableData
      })
    }).catch((res)=>{
        console.log(res);
    });
  }
  onOff(checked,key){
    console.log(checked,key)
  }
  render() {

    const columns = [{
      title: '岗位',
      dataIndex: 'post',
    }, {
      title: '人数',
      dataIndex: 'number',
    }, {
      title: '岗位要求',
      dataIndex: 'description',
      render: (text) => <span className="col-sql">{text}</span>,
    },
    {
      title: '时间',
      dataIndex: 'time',
    },{
      title: '操作',
      dataIndex: 'key', key: 'x', render: (data, record) => 
          <div>
              <Link to={
                  {
                    pathname:"/Home/RecruitmentEdit", 
                    state: {data:`${record.key}`}  
                  }
              }
              ><Button icon="form" style={{marginRight:"12px",outline: "none",border: "none",boxShadow: "none",marginLeft:"-16px"}} onClick={this.amend.bind(this,record.key)}>修改</Button></Link>              
              <Popconfirm title="确定删除" okText="确定" cancelText="取消" onConfirm={this.clear.bind(this,record.key)}>
                <Button icon="delete" style={{marginRight:"12px",outline: "none",border: "none",boxShadow: "none"}} >删除</Button>
              </Popconfirm>
              {/* <Switch checkedChildren="显示" unCheckedChildren="不显示" defaultChecked onChange={this.onOff.bind(this,record.key)}/> */}
          </div>
    }
  ];
   

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div id='recruitment'>
          <Link to='/Home/RecruitmentEdit'><Button type="primary" icon="plus" onClick={this.rua.bind(this)}>新建</Button></Link>
          <div className="info" style={{display:this.state.selectedRowKeys.length ? "block" : "none"}}>
            <Button icon="exclamation-circle" style={{border:"none",outline:"none",boxShadow:"none",color:"#2a8ff6",backgroundColor:"#e5f7ff"}}></Button>
            <span>已选择<span className="addNumber">{this.state.allNumber || 0}</span>项</span>
            <Popconfirm title="确定删除" okText="确定" cancelText="取消" onConfirm={this.clear.bind(this,this.state.selectedRowKeys)}>
              <span className="clearAll">删除</span>
            </Popconfirm>            
          </div>
          <div>
          <div style={{ marginBottom: 16 }}>
            <span style={{ marginLeft: 8 }}>
            </span>
            </div>
            <Spin spinning={this.state.spinning}>
              <Table locale={{emptyText: '暂无数据' }} rowSelection={rowSelection} pagination={{total:this.state.total}} onChange={this.myOnchange.bind(this)} columns={columns} dataSource={this.state.tableData} />
            </Spin>
          </div>
      </div>
    );
  }
}

export default Recruitment;

