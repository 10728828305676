/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-05-31 19:13:48
 * @LastEditTime: 2019-08-20 09:52:46
 * @LastEditors: Please set LastEditors
 */

import React, { Component } from 'react';
import {
  Input,
  Button,
  Select,
  Spin,
  message,
  Upload,
  Icon,
  Modal,
  DatePicker
} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/table.css';
import BraftEditor from 'braft-editor';
import { ContentUtils } from 'braft-utils';
import './ConsultEdit.css';
import PreviewModal from '../PreviewModal';
import { Ajax } from '../../utils/ajax';
import cookie from '../../utils/utils';
const { RangePicker } = DatePicker;
const getcookie = cookie.getCookie;
const controls = [
  'undo',
  'redo',
  'separator',
  'font-size',
  'font-family',
  'line-height',
  'letter-spacing',
  'separator',
  'text-color',
  'bold',
  'italic',
  'underline',
  'strike-through',
  'separator',
  'superscript',
  'subscript',
  'remove-styles',
  'emoji',
  'separator',
  'text-indent',
  'text-align',
  'separator',
  'headings',
  'list-ul',
  'list-ol',
  'blockquote',
  'code',
  'separator',
  'link',
  'separator',
  'hr',
  'separator',
  'media',
  'separator',
  'clear'
];
// 富文本字体设置
const font = [
  {
    name: '宋体',
    family: 'SimSun'
  },
  {
    name: '黑体',
    family: 'SimHei'
  },
  {
    name: '微软雅黑',
    family: 'Microsoft YaHei'
  },
  {
    name: '微软正黑体',
    family: 'Microsoft JhengHei'
  },
  {
    name: '新宋体',
    family: 'NSimSun'
  },
  {
    name: '新细明体',
    family: 'PMingLiU'
  },
  {
    name: '仿宋',
    family: 'FangSong'
  },
  {
    name: '楷体_GB2312',
    family: 'KaiTi_GB2312'
  },
  {
    name: '仿宋_GB2312',
    family: 'FangSong_GB2312'
  }
];
// 富文本媒体上传
const myUploadFn = (param, e) => {
  fetch('/zhongcai/oss/getSign', {
    headers: new Headers({
      token: getcookie('token')
    })
  })
    .then(res => {
      return res.json();
    })
    .then(res => {
      // const serverURL = '/zhongcai/file/upload'
      const serverURL = res.host;
      const xhr = new XMLHttpRequest();
      const fd = new FormData();
      const key = `${new Date().getTime()}_${param.file.name}`;
      fd.append('OSSAccessKeyId', res.accessid);
      fd.append('policy', res.policy);
      fd.append('Signature', res.signature);
      fd.append('key', key);
      fd.append('success_action_status', '200');
      const successFn = response => {
        // let res = JSON.parse(xhr.responseText)
        param.success({
          url: res.host + '/' + key,
          meta: {
            id: '',
            title: '',
            alt: '',
            loop: true, // 指定音视频是否循环播放
            autoPlay: false, // 指定音视频是否自动播放
            controls: true, // 指定音视频是否显示控制栏
            poster: '' // 指定视频播放器的封面
          }
        });
      };
      const progressFn = event => {
        // 上传进度发生变化时调用param.progress
        param.progress((event.loaded / event.total) * 100);
      };
      const errorFn = response => {
        // 上传发生错误时调用param.error
        param.error({
          msg: 'unable to upload.'
        });
      };
      xhr.upload.addEventListener('progress', progressFn, false);
      xhr.addEventListener('load', successFn, false);
      xhr.addEventListener('error', errorFn, false);
      xhr.addEventListener('abort', errorFn, false);
      fd.append('file', param.file);
      xhr.open('POST', serverURL, true);
      xhr.send(fd);
    });
};
// 富文本上传大小设置
const myValidateFn = file => {
  console.log(file);
  return file.size < 1024 * 1024 * 200;
};

class ConsultEdit extends Component {
  state = {
    classify: '',
    title: '',
    sid: '',
    pid: '',
    //默认选择
    ruavalue: '',
    editorState: '', // 设置编辑器初始内容
    outputHTML: '',
    spinning: false,
    previewVisible: false,
    previewImage: '',
    imgData: '',
    fileList: [],
    isCreate: false,
    limit: false,
    originValue: '',
    contentPreview: false,
    waitUploadList: [],
    showUploadList: [],
    inputValue: '',
    createTime: '',
    createat: ''
  };
  componentWillUnmount() {}

  handleChange = editorState => {
    this.setState({
      editorState: editorState,
      outputHTML: editorState.toHTML()
    });
  };

  componentDidMount() {
    if (this.props.location.state) {
      //修改跳进来
      if (this.props.location.state.data) {
        this.setState({
          spinning: true
        });
        //传递过来的id
        let transmitId = this.props.location.state.data;
        //单条数据查询
        fetch('/zhongcai/information/' + transmitId, {
          method: 'GET',
          headers: new Headers({
            token: getcookie('token')
          })
        })
          .then(res => {
            return res.json();
          })
          .then(res => {
            this.setState({
              spinning: false
            });
            let data = res.data.information;
            let pid = res.data.information.primaryId;
            let sid = res.data.information.secondaryId;
            if (sid === 15) {
              this.setState({
                isCreate: true
              });
            }
            this.setState({
              editorState: BraftEditor.createEditorState(data.content),
              fileList: [
                {
                  url: res.data.information.image,
                  uid: '-1'
                }
              ],
              sid,
              pid,
              inputValue: data.title,
              originValue: data.source,
              createat: data.createat
            });
            //获取菜单信息
            fetch('/zhongcai/menu?ids=' + pid + '%2C' + sid, {
              method: 'GET',
              headers: new Headers({
                token: getcookie('token')
              })
            })
              .then(res => {
                return res.json();
              })
              .then(res => {
                let pname = res.data.menuList[0].name;
                let sname = res.data.menuList[1].name;
                if (res.data.menuList[1].id === 37) {
                  this.setState({
                    ruavalue: sname
                  });
                } else {
                  this.setState({
                    ruavalue: pname + '>' + sname
                  });
                }
              });
          })
          .catch(res => {
            this.setState({
              spinning: false
            });
          });
      }
      //新建
      else {
        let pid = window.localStorage.pid;
        let sid = window.localStorage.sid;
        if (sid === '15') {
          this.setState({
            isCreate: true
          });
        }
        if (pid) {
          this.setState({
            pid: parseInt(pid),
            sid: parseInt(sid)
          });
        }
        //http://120.26.61.246:9002/zhongcai/menu?ids=1%2C9
        console.log('/zhongcai/menu?ids=' + pid + '%2C' + sid);
        fetch('/zhongcai/menu?ids=' + pid + '%2C' + sid, {
          method: 'GET',
          headers: new Headers({
            token: getcookie('token')
          })
        })
          .then(res => {
            return res.json();
          })
          .then(res => {
            let pname = res.data.menuList[0].name;
            let sname = res.data.menuList[1].name;
            if (res.data.menuList[1].id === 37) {
              this.setState({
                ruavalue: sname,
                pid: pid,
                sid: sid
              });
            } else {
              this.setState({
                ruavalue: pname + '>' + sname,
                pid: pid,
                sid: sid
              });
            }
          })
          .catch(res => {
            console.log(res);
          });
      }
    }
  }

  rua() {
    if (this.state.inputValue === '') {
      message.info('标题不能为空');
      return;
    }
    if (this.state.editorState.isEmpty()) {
      message.info('内容不能为空');
      return;
    } else if (!this.state.editorState.isEmpty()) {
      if (this.state.editorState.toHTML().trim() === '') {
        message.info('内容不能为空');
        return;
      }
    }
    this.setState({ spinning: true });
    let json = {
      content: this.state.outputHTML,
      createuser: 0,
      updateuser: 0,
      primaryId: this.state.pid,
      secondaryId: this.state.sid,
      image: this.state.imgData ? this.state.imgData : null,
      title: this.state.inputValue,
      shows: 0,
      source: this.state.originValue,
      createat: this.state.createTime
    };
    console.log(json);
    if (this.props.location.state.data) {
      let myid = this.props.location.state.data;
      json.id = myid;
      // http://120.26.61.246:9002/zhongcai/information/
      fetch('/zhongcai/information/', {
        method: 'PUT',
        body: JSON.stringify(json),
        headers: new Headers({
          'Content-Type': 'application/json',
          token: getcookie('token')
        })
      })
        .then(res => {
          if (res.status >= 500) {
            message.error('服务器故障， 请稍后重试', 3);
            return false;
          }
          return res.json();
        })
        .then(res => {
          if (!res) {
            return false;
          }
          this.setState({
            spinning: false
          });
          window.history.back();
          message.info('修改成功');
        })
        .catch(res => {
          message.info('修改失败');
        });
    } else {
      // http://120.26.61.246:9002/zhongcai/information/

      fetch('/zhongcai/information/', {
        method: 'POST',
        body: JSON.stringify(json),
        headers: new Headers({
          'Content-Type': 'application/json',
          token: getcookie('token')
        })
      })
        .then(res => {
          return res.json();
        })
        .then(res => {
          this.setState({
            spinning: false
          });
          message.info('新建成功');
          window.history.back();
        })
        .catch(res => {
          message.info('新建失败');
        });
    }
  }

  inputChange(type, e) {
    if (type === 'origin') {
      this.setState({
        originValue: e.currentTarget.value
      });
      return;
    }
    this.setState({
      inputValue: e.currentTarget.value
    });
  }

  back() {
    window.history.back();
  }

  handleCancel = () => this.setState({ previewVisible: false });

  uploadPreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    });
  };

  // beforeUpload(file) {
  //   console.log(file)
  //   const isLt2M = file.size / 1024 / 1024 < 2
  //   if (!isLt2M) {
  //     // message.error('请上传不大于1M大小的图片！');
  //   }
  //   return  isLt2M;
  // }

  uploadChange = e => {
    this.setState({
      fileList: e.fileList
      // imgData:  fileList.length === 0 ? null : fileList[0].thumbUrl
    });
    // if(e.file&&e.file.response) {
    //   let fileUrl = e.file.response.data.fileUrl
    //   this.setState({
    //     imgData: fileUrl
    //   })
    // }
  };

  //预览富文本内容
  preview() {
    this.setState({
      contentPreview: true
    });
  }
  //关闭预览窗口
  closePreview() {
    this.setState({
      contentPreview: false
    });
  }

  customRequest = (type, detail) => {
    this.setState({ spinning: true });
    let file = detail.file;
    fetch('/zhongcai/oss/getSign', {
      headers: new Headers({
        token: getcookie('token')
      })
    })
      .then(res => {
        return res.json();
      })
      .then(res => {
        const serverURL = res.host.replace('http://', 'https://');
        const xhr = new XMLHttpRequest();
        const fd = new FormData();
        const key = `${new Date().getTime()}_${file.name}`;
        fd.append('OSSAccessKeyId', res.accessid);
        fd.append('policy', res.policy);
        fd.append('Signature', res.signature);
        fd.append('key', 'zhongcai/' + key);
        fd.append('success_action_status', '200');
        const successFn = response => {
          detail.onSuccess(serverURL + key);
          if (type === 'img') {
            this.setState({
              imgData: serverURL + '/zhongcai/' + key,
              spinning: false
            });
            return;
          }
          this.setState({
            editorState: ContentUtils.insertHTML(
              this.state.editorState,
              `<p><a href="${serverURL + '/zhongcai/' + key}" download>${
                file.name
              }</a><p>`
            ),
            spinning: false
          });
        };
        const progressFn = event => {
          // 上传进度发生变化时调用param.progress
          let percent = (event.loaded / event.total) * 100;
          detail.onProgress({
            percent
          });
        };
        const errorFn = response => {
          // 上传发生错误时调用param.error
          detail.onError();
        };
        xhr.upload.addEventListener('progress', progressFn, false);
        xhr.addEventListener('load', successFn, false);
        xhr.addEventListener('error', errorFn, false);
        xhr.addEventListener('abort', errorFn, false);
        fd.append('file', file);
        xhr.open('POST', serverURL, true);
        xhr.send(fd);
      });
    return false;
  };

  getFileExtendingName(filename) {
    // 文件扩展名匹配正则
    var reg = /\.[^\.]+$/;
    var matches = reg.exec(filename);
    if (matches) {
      return matches[0];
    }
    return '';
  }
  uploadWord(detail) {
    this.setState({ spinning: true });
    let file = detail.file;
    let fd = new FormData();
    fd.append('file', file);
    const url =
      this.getFileExtendingName(file.name) === '.doc'
        ? 'word2003ToHtml'
        : 'word2007ToHtml';
    fetch(`/zhongcai/word/${url}`, {
      method: 'POST',
      headers: new Headers({
        token: getcookie('token')
      }),
      body: fd
    })
      .then(res => {
        return res.json();
      })
      .then(res => {
        this.setState({
          editorState: BraftEditor.createEditorState(res.data.content),
          spinning: false
        });
      })
      .catch();
  }

  changeDate(time) {
    this.setState(
      {
        createTime: moment(time).format('YYYY-MM-DD HH:mm:ss')
      },
      () => {
        console.log(this.state.createTime);
      }
    );
  }

  render() {
    function disabledDate(current) {
      return current && current > moment().endOf('day');
    }
    const { previewVisible, previewImage, fileList } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const extendControls = [
      {
        key: 'antd-uploader',
        type: 'component',
        component: (
          <Upload
            showUploadList={false}
            customRequest={this.customRequest.bind(this, 'braft')}
            beforeUpload={this.beforeUpload}
            multiple
            accept=".docx,.zip,.rar,.pptx,.pdf,.doc"
          >
            {/* 这里的按钮最好加上type="button"，以避免在表单容器中触发表单提交，用Antd的Button组件则无需如此 */}
            <button
              type="button"
              className="control-item button upload-button"
              data-title="上传附件"
            >
              <Icon type="upload" />
            </button>
          </Upload>
        )
      },
      {
        key: 'antd-upload-word',
        type: 'component',
        component: (
          <Upload
            showUploadList={false}
            customRequest={this.uploadWord.bind(this)}
            multiple
            accept=".doc,.docx"
          >
            {/* 这里的按钮最好加上type="button"，以避免在表单容器中触发表单提交，用Antd的Button组件则无需如此 */}
            <button
              type="button"
              className="control-item button upload-button"
              data-title="上传文档"
            >
              <Icon type="file-word" />
            </button>
          </Upload>
        )
      }
    ];

    return (
      <Spin spinning={this.state.spinning}>
        <div className="App">
          <p style={{ textAlign: 'left' }}>标题</p>
          <Input
            placeholder="标题"
            size="large"
            ref="title"
            value={this.state.inputValue}
            onChange={this.inputChange.bind(this, 'title')}
          />
          <p style={{ textAlign: 'left', marginTop: '10px' }}>来源</p>
          <Input
            placeholder="请输入来源"
            size="large"
            ref="origin"
            value={this.state.originValue}
            onChange={this.inputChange.bind(this, 'origin')}
          />
          <p style={{ textAlign: 'left', marginTop: '10px' }}>分类</p>
          <Select
            ref="select"
            showSearch
            disabled
            size="large"
            style={{ width: 200, float: 'left', marginBottom: '12px' }}
            placeholder="分类"
            optionFilterProp="children"
            defaultValue={this.state.ruavalue || ''}
            value={this.state.ruavalue || ''}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          />
          <div style={{ clear: 'both' }} />
          <div
            className="testtest"
            style={{ display: this.state.isCreate ? 'block' : 'none' }}
          >
            <p style={{ textAlign: 'left', marginTop: '10px' }}>新闻封面</p>
            <div className="clearfixTest">
              <Upload
                listType="picture-card"
                fileList={fileList}
                // action="/zhongcai/oss/uploadFile"
                customRequest={this.customRequest.bind(this, 'img')}
                onPreview={this.uploadPreview}
                onChange={this.uploadChange}
                // beforeUpload={this.beforeUpload}
                accept="image/*"
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                footer={null}
                onCancel={this.handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: '100%' }}
                  src={previewImage}
                />
              </Modal>
            </div>
          </div>
          <p style={{ textAlign: 'left', marginTop: '10px' }}>发布时间</p>
          <DatePicker
            style={{ width: 200, marginBottom: '12px' }}
            showTime
            disabledDate={disabledDate}
            format="YYYY-MM-DD HH:mm:ss"
            placeholder={
              this.state.createat ? this.state.createat : '请选择发布时间'
            }
            onChange={moment => this.changeDate(moment)}
          />
          {/* <DatePicker renderExtraFooter={() => 'extra footer'} onChange={this.changeDate} showTime placeholder="请选择时间" /> */}
          <div style={{ clear: 'both' }} />
          <p style={{ textAlign: 'left' }}>内容</p>
          <BraftEditor
            value={this.state.editorState}
            onChange={this.handleChange}
            controls={controls}
            fontFamilies={font}
            extendControls={extendControls}
            media={{
              validateFn: myValidateFn,
              uploadFn: myUploadFn
            }}
          />
          <Button
            onClick={this.back}
            style={{
              marginTop: '36px',
              float: 'right',
              background: '#004894',
              color: '#fff',
              height: '45px',
              width: '120px',
              fontSize: '17px'
            }}
            icon="backward"
          >
            返回
          </Button>
          <Button
            onClick={this.rua.bind(this)}
            style={{
              marginTop: '36px',
              float: 'right',
              marginRight: '36px',
              background: '#E50012',
              color: '#fff',
              height: '45px',
              width: '120px',
              fontSize: '17px'
            }}
            icon="upload"
          >
            发布
          </Button>
          <Button
            onClick={this.preview.bind(this)}
            style={{
              marginTop: '36px',
              float: 'right',
              marginRight: '36px',
              background: 'deepskyblue',
              color: '#fff',
              height: '45px',
              width: '120px',
              fontSize: '17px'
            }}
            icon="eye"
          >
            预览
          </Button>
        </div>
        {this.state.contentPreview ? (
          <PreviewModal
            showHtml={this.state.outputHTML}
            title={this.state.inputValue}
            closePreview={this.closePreview.bind(this)}
            origin={this.state.originValue}
          />
        ) : (
          ''
        )}
      </Spin>
    );
  }
}

export default ConsultEdit;
