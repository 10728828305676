import React, { Component } from 'react'
import { Upload, Modal, Icon, Button, message, Spin, Select } from 'antd'
import './index.css'
import { Ajax } from '../../utils/ajax';
import { withRouter } from 'react-router-dom'
import cookie from '../../utils/utils'
const getcookie = cookie.getCookie
const { Option } = Select
export default withRouter(class AddBanner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fileList: [],
      previewVisible: false,
      previewImage: '',
      spinning: false,
      isEdit: false,
      bannerId: null,
      selectValue: "首页",
      selectKey: 1
    }
  }
  componentDidMount() {
    let state = this.props.location.state
    if (state) {
      this.setState({ spinning: true, isEdit: state.isEdit, bannerId: state.bannerId })
      Ajax.GET(`/zhongcai/banner/findOne?id=${state.bannerId}`)
        .then(res => {
          let data = res.data.banner
          this.setState({
            productId: data.productId,
            selectKey: data.bannerType,
            selectValue: this.initSelectValue(data.bannerType),
            fileList: [{
              uid: '-1',
              status: 'done',
              response: data.imgUrl,
              thumbUrl: data.imgUrl
            }],
            spinning: false
          })
        })
        .catch(err => {
          this.setState({ spinning: false })
        })
    }
  }
  initSelectValue(type) {
    switch (type) {
      case 1: return "首页"
      case 2: return "长仲风采"
      case 3: return "仲裁指南"
      case 4: return "仲裁员"
      case 5: return "在线服务"
      case 6: return "仲裁鉴定"
      case 7: return "仲裁资料"
      case 8: return "分支机构"
      case 9: return "基层党建"
    }
  }
  /**
 * 自定义图片上传
 * @param {上传文件详细信息} detail
 */
  customRequest = (detail) => {
    let file = detail.file
    fetch('/zhongcai/oss/getSign', {
      headers: new Headers({
        'token': getcookie('token')
      })
    })
      .then(res => {
        return res.json()
      })
      .then(res => {
        const serverURL = res.host
        const xhr = new XMLHttpRequest()
        const fd = new FormData()
        const key = `${new Date().getTime()}_${file.name}`;
        fd.append('OSSAccessKeyId', res.accessid);
        fd.append('policy', res.policy);
        fd.append('Signature', res.signature);
        fd.append('key', 'ywstore/' + key);
        fd.append('success_action_status', '200');
        const successFn = (response) => {
          detail.onSuccess(res.host + '/ywstore/' + key)
        }
        const progressFn = (event) => {
          // 上传进度发生变化时调用param.progress
          let percent = event.loaded / event.total * 100
          detail.onProgress({
            percent
          })
        }
        const errorFn = (response) => {
          // 上传发生错误时调用param.error
          detail.onError()
        }
        xhr.upload.addEventListener("progress", progressFn, false)
        xhr.addEventListener("load", successFn, false)
        xhr.addEventListener("error", errorFn, false)
        xhr.addEventListener("abort", errorFn, false)
        fd.append('file', file)
        xhr.open('POST', serverURL, true)
        xhr.send(fd)
      })
    return false
  }
  handleChange = ({ fileList }) => this.setState({ fileList })
  handleCancel = () => this.setState({ previewVisible: false })
  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }
  getChildData(data) {
    this.setState({
      productId: data.productId,
      productInfo: data
    })
  }
  sureAdd() {
    if (!this.state.fileList.length) {
      message.error("请先上传图片！", 3)
      return
    }
    let obj = {
      bannerType: this.state.selectKey,
      imgUrl: this.state.fileList[0].response
    }
    this.setState({ spinning: true })
    if (this.state.isEdit) {
      obj.id = this.state.bannerId
      Ajax.PUT('/zhongcai/banner/', obj, {
        headers: new Headers({
          'token': getcookie('token')
        })
      })
        .then(res => {
          message.success('修改成功！', 3)
          this.setState({ spinning: false })
          setTimeout(() => {
            this.props.history.push('/Home/bannerSetting')
          }, 1000)
        })
        .catch(err => {
          message.error('修改失败:' + err, 3)
          this.setState({ spinning: false })
        })
      return
    }
    Ajax.POST('/zhongcai/banner/', obj, {
      headers: new Headers({
        'token': getcookie('token')
      })
    })
      .then(res => {
        message.success('添加成功！', 3)
        this.setState({ spinning: false })
        setTimeout(() => {
          this.props.history.push('/Home/bannerSetting')
        }, 1000)
      })
      .catch(err => {
        message.error('添加失败:' + err, 3)
        this.setState({ spinning: false })
      })
  }
  selectChange(key, e) { this.setState({ selectKey: key, selectValue: e.props.children }) }

  caretePid0() {
    let obj = {
      name: "影视文化仲裁院",
      pid: 7,
      remarks: ""
    }
    fetch(`/zhongcai/menu?name=${obj.name}&pid=${obj.pid}&remarks=${obj.remarks}`, {
      method: "POST",
      body: JSON.stringify(obj),
      headers: new Headers({
        'Content-Type': 'application/json',
        'token': getcookie('token')
      })
    }).then((res) => {
      return res.json();
    }).then(res => {
      this.setState({
        spinning: false
      })

    }).catch((res) => {

    });
  }


  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return <Spin spinning={this.state.spinning}>
      <div id="add-banner">
        <p style={{ fontSize: '18px', color: '#999' }}>Banner所在模块</p>
        <Select size="large" value={this.state.selectValue} selectKey={this.state.selectKey} onChange={this.selectChange.bind(this)} style={{ width: 120 }}>
          <Option value="1">首页</Option>
          <Option value="2">长仲风采</Option>
          <Option value="3">仲裁指南</Option>
          <Option value="4">仲裁员</Option>
          <Option value="5">在线服务</Option>
          <Option value="6">仲裁鉴定</Option>
          <Option value="7">仲裁资料</Option>
          <Option value="8">分支机构</Option>
          <Option value="9">基层党建</Option>
        </Select>
        <p style={{ marginTop: '40px', fontSize: '18px', color: '#999' }}>上传图片（大小750*300px，格式jpg、gif、png）</p>
        <Upload
          customRequest={this.customRequest}
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          accept="image/*"
        >
          {fileList.length >= 1 ? null : uploadButton}
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
        <div>
          <Button type="primary" onClick={this.sureAdd.bind(this)}>{this.state.isEdit ? '确认修改' : '确认添加'}</Button>
          <Button onClick={() => { this.props.history.goBack() }}>取消</Button>
        </div>
      </div>
    </Spin>
  }
})