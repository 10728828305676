import React, { Component } from 'react';
import { Button, Table, Popconfirm, message, Icon, Select } from 'antd';
import { Ajax } from '../../utils/ajax'
import './index.css'
import { Link } from 'react-router-dom'
const { Option } = Select
class MarketingManage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableLoading: false,
      tableData: [],
      selectKey: 1,
      selectValue: '首页',
      columns: [
        {
          title: '图片',
          dataIndex: 'img',
          render: (text, record) => {
            return <div style={{width: '50px', height: '50px', overflow: 'hidden'}}>
              <img src={record.img} style={{width: '100%'}} alt=""/>
            </div>
          }
        }, {
          title: '所在模块',
          dataIndex: 'bannerType',
          render: (text) => {
            return this.initTypeValue(text)
          }
        }, {
          title: '操作',
          dataIndex: 'operation',
          render: (text, record) => {
            return (
              <div>      
                  <span
                    style={{ marginRight: 8, color: '#2a8ff6', cursor: "pointer" }}
                  >
                    <Link to={{
                      pathname: '/Home/addBanner',
                      state: {
                        bannerId: record.key,
                        isEdit: true
                      }
                    }}>修改</Link>
                  </span>
                  <Popconfirm title="确定删除？" onConfirm={this.delGoods.bind(this, record.key)} >
                    <span
                      style={{ marginRight: 8 }}
                      className="delete"
                    >
                      删除
                    </span>
                </Popconfirm>
              </div>
            );
          },
        }
      ]
    }
  }

  initTypeValue(type) {
    switch(type) {
      case 1: return "首页"
      case 2: return "长仲风采"
      case 3: return "仲裁指南"
      case 4: return "仲裁员"
      case 5: return "在线服务"
      case 6: return "仲裁鉴定"
      case 7: return "仲裁资料"
      case 8: return "分支机构"
      case 9: return "基层党建"
    }
  }

  delGoods(id) {
    this.setState({tableLoading: true})
    Ajax.DELETE(`/zhongcai/banner/${id}`)
    .then(res => {
      message.success("删除成功！", 3)
      this.setState({tableLoading: false})
      this.getData()
    })
    .catch(err => {
      console.log(err)
      message.error("删除失败：" + err, 3)
      this.setState({tableLoading: false})
    })
  }

  componentDidMount() {
    this.getData()
  }

  getData(type=null) {
    this.setState({tableLoading: true})
    let url = type ? `/zhongcai/banner/queryByType?pageSize=30&bannerType=${type}` : '/zhongcai/banner/queryByType?pageSize=30'
    Ajax.GET(url)
    .then(res => {
      let bannerList = res.data.pageInfo.list,
          tableData = []
      bannerList.forEach(item => {
        tableData.push({
          img: item.imgUrl,
          key: item.id,
          bannerType: item.bannerType
        })
      })
      this.setState({tableData, tableLoading: false})
    })
    .catch(err => {
      this.setState({tableLoading: false})
    })
  }

  selectChange(key, e) {
    this.setState({selectKey: key, selectValue: e.props.children})
    this.getData(key)
  }
  render() {
    return <div id="banner-setting">
      <div>
        <Link to="/Home/addBanner"><Button type="primary"><Icon type="plus" />新建</Button></Link>
        <Select value={this.state.selectValue} selectKey={this.state.selectKey} onChange={this.selectChange.bind(this)} style={{width: '120px', float: 'right'}}>
          <Option key="1">首页</Option>
          <Option key="2">长仲风采</Option>
          <Option key="3">仲裁指南</Option>
          <Option key="4">仲裁员</Option>
          <Option key="5">在线服务</Option>
          <Option key="6">仲裁鉴定</Option>
          <Option key="7">仲裁资料</Option>
          <Option key="8">分支机构</Option>
          <Option key="9">基层党建</Option>
        </Select>
      </div>
      <Table 
        columns={this.state.columns} 
        dataSource={this.state.tableData} 
        loading={this.state.tableLoading}
        pagination={false}
      />
    </div>
  }
}
export default MarketingManage