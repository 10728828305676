import React from "react"
import './index.css'
import { Button , Table , Spin , message, Popconfirm} from 'antd';
import cookie from '../../utils/utils'
const getCookie = cookie.getCookie

class resume extends React.Component {
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    loading: false,
    tableData: [],
    //已选择的个数
    allNum:0,
    total:0,
    spinning:false
  };
  rua(){
    console.log(1111)
    console.log(this.props)
  }
  //修改
  create = (key) => {
    console.log("修改")
  }

  start = () => {
    this.setState({ loading: true });
  // ajax request after empty completing
    setTimeout(() => {
        this.setState({
        selectedRowKeys: [],
        loading: false,
        //总数
        allNumber:0
        });
    }, 1000);
  }
  onSelectChange = (selectedRowKeys) => {
    this.setState({ 
      selectedRowKeys: selectedRowKeys,
      allNumber:selectedRowKeys.length
    });
  }

  myOnchange(pagination, filters, sorter){
    this.setState({
      spinning:true
    })
    //  http://120.26.61.246:9002/zhongcai/apply_job?pageNum=1&pageSize=10
     fetch('/zhongcai/apply_job/?pageNum='+pagination.current+'&pageSize=10', {
      headers: new Headers({
        'token': getCookie('token')
      })
     }).then((res)=>{
      return res.json();
    }).then(res => {
      this.setState({
        spinning:false,
        total:res.data.pageInfo.total
      })
      let data = res.data.pageInfo.list
      let arr = []
      data.forEach((item, index) => {
        let obj = {
          key: item.id,
          name: item.name,
          phone: item.phone,
          content: item.resume,
          time:item.createat
        }
        arr[index] = obj
      });
      this.setState({
        tableData:arr
      })
    }).catch((res)=>{
        console.log(res);
    });
    
  }

  //多选清空&&单清空
  clear(arr){
    this.setState({
      spinning:true
    })
    // http://120.26.61.246:9002/zhongcai/apply_job/5
    let arrSplice = this.state.tableData
    if(typeof arr == "number"){
      fetch('/zhongcai/apply_job/'+arr,{
        method:"DELETE",
        headers: new Headers({
          'token': getCookie('token')
        })
      }).then((res)=>{
        return res.json();
      }).then(res => {
        this.setState({
          spinning:false
        })
        arrSplice.forEach((item,index,array)=>{
          if(item.key===arr){
            array.splice(index,1)
          }
        })
        this.setState({
          tableData:arrSplice
        })
        message.info("删除成功")
        this.componentDidMount()
      }).catch((res)=>{
        message.info("删除失败")
      });
    }
    else {
      for(var i=0;i<arrSplice.length;i++){
        for(var j=0;j<arr.length;j++){
          if(arrSplice[i].key === arr[j] ){
            arrSplice.splice(i,1)
            fetch('/zhongcai/apply_job/'+arr[j],{
              method:"DELETE",
              headers: new Headers({
                'token': getCookie('token')
              })
            }).then((res)=>{
              return res.json();
            }).then(res => {
              this.setState({
                spinning:false
              })
              message.info("删除成功")
              this.componentDidMount()
            }).catch((res)=>{
                message.info("删除失败")
            });
          }
        }
      }
    }
    
   
    this.setState({
      tableData:arrSplice
    })
  }

  componentDidMount(){
    this.setState({
      spinning:true
    })
    
     //所有列表
   // http://120.26.61.246:9002/zhongcai/apply_job?pageSize=10
    fetch('/zhongcai/apply_job?pageSize=10', {
      headers: new Headers({
        'token': getCookie('token')
      })
    }).then((res)=>{
      return res.json();
    }).then(res => {
        console.log(res)
      this.setState({
        spinning:false
      })
      let data = res.data.pageInfo.list
      let tableData = this.state.tableData
      
      data.forEach((item, index) => {
        let obj = {
          key: item.id,
          name: item.name,
          phone: item.phone,
          content: item.resume,
          time:item.updateat
        }
        tableData[index] = obj
      });
      this.setState({
        tableData:tableData,
        total:res.data.pageInfo.total
      })
    }).catch((res)=>{
        console.log(res);
    });
  }
  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const columns = [{
      title: '姓名',
      dataIndex: 'name',
    }, {
      title: '电话',
      dataIndex: 'phone',
    }, {
      title: '简历下载',
      dataIndex: 'content',
      // 下载
      //  <a href={file} download style={{textDecoration:'underline'}}>《仲裁员申请表》</a>(点击下载)
      render: (text) => <span className="col-sql"><a href={"http://" + text} download>下载简历</a></span>,
    },
    {
      title: '时间',
      dataIndex: 'time',
    },{
      title: '操作',
      dataIndex: 'key', key: 'x', render: (data, record) => 
        <div>
          <Popconfirm title="确定删除" okText="确定" cancelText="取消" onConfirm={this.clear.bind(this,record.key)}>
            <Button icon="delete" style={{marginRight:"12px",outline: "none",border: "none",boxShadow: "none",marginLeft:"-16px"}}>删除</Button>
          </Popconfirm>              
        </div>
    }
  ];
   
    return (
      <div id='feedback'>
      {/* 新的地址 */}
          <div className="info" style={{display:this.state.selectedRowKeys.length ? "block" : "none"}}>
            <Button icon="exclamation-circle" style={{border:"none",outline:"none",boxShadow:"none",color:"#2a8ff6",backgroundColor:"#e5f7ff"}}></Button>
            <span>已选择<span className="addNumber">{this.state.allNumber || 0}</span>项</span>
            <span className="clearAll" onClick={this.clear.bind(this,this.state.selectedRowKeys)}>删除</span>
          </div>

          <div>
          <div style={{ marginBottom: 16 }}>
            <span style={{ marginLeft: 8 }}>
            </span>
            </div>
            <Spin spinning={this.state.spinning}>
              <Table locale={{emptyText: '暂无数据' }} rowSelection={rowSelection} pagination={{total:this.state.total}} onChange={this.myOnchange.bind(this)} columns={columns} dataSource={this.state.tableData} />
            </Spin>
          </div>
      </div>
    );
  }
}

export default resume;

